import React from 'react';
import "./index.css"

const QueueTicketWelcome = ({name, content, morecontent}) => {
    return (
        <div className="queue-ticket-welcome">
            <h3>Hi {name}!</h3>
            <p style={{whiteSpace: "pre-wrap"}}>{content}</p>
            <p>{morecontent}</p>
        </div>
    )
}

export default QueueTicketWelcome;
