import React from 'react';
import "./index.css";

const CheckBox = ({name, value,setValue}) => {
    return (
        <div className="check-box">
            <div className="round">
                <input type="checkbox" id="checkbox" checked={value}  onChange={(e) => setValue(e.target.checked)} />
                <label htmlFor="checkbox"></label>
            </div>
            <div className="chechbox-name" >{name}</div>
        </div>
    )
}

export default CheckBox;
