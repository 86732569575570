import React, { useEffect,useState} from 'react'
import { withRouter } from 'react-router'
import ManagerHeader from '../../../components/ManageHeaders'
import TicketTables from "./components/TicketTables"
import _ from 'lodash';
import Spinner from '../../../components/Spinner';
import Footer from '../../../components/Footer';
import axios from 'axios';

import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../firebase';

const ManageHome =  ({merchantKey, storeInfo}) => {
    // const smsPartnerBalance = JSON.parse(localStorage.getItem("sms_patner_info"))

    const date = new Date().toISOString().replace(/T.*/,'').split('-').reverse().join('-')

    const [tickets] = useCollectionData(
    firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).where('status', 'not-in', ['NO_SHOW', 'SEATED', 'EXIT_QUEUE']),
    {idField: 'id'}
    );


    // const tickets = _.groupBy(ticketsQ, 'queue_type_id')

    const [smsPartnerBalance, setSmsPartnerBalance] = useState(JSON.parse(localStorage.getItem("sms_patner_info")));

    useEffect(() => {
        const current_time = new Date().getTime()
        // const timeout = smsPartnerBalance?.timeout
        const fetchData = async () => {
            axios.get(`${process.env.REACT_APP_SMARTQ_API_BASE_URL}/store/${merchantKey}/sms-partner-account`)
              .then(({data}) => 
                { 
                    const obj = {balance: data.data?.balance, timeout: current_time + 20*60*1000, 
                        auto_recharge:  data.data?.auto_recharge ? true : false}

                    localStorage.setItem("sms_patner_info", 
                        JSON.stringify(obj));
                   setSmsPartnerBalance(obj)
                }
                
                )
              .catch((e) => console.error(e));
          };
        if(!smsPartnerBalance?.timeout ) {
             fetchData();
        } else if (current_time >  +smsPartnerBalance?.timeout && !smsPartnerBalance?.auto_recharge ) {
            fetchData();
        }

        return 
        
    }, [merchantKey, smsPartnerBalance]);
    

    return (
        <>
        {storeInfo?.enable_sms_alerts && smsPartnerBalance?.balance && !smsPartnerBalance?.auto_recharge && +smsPartnerBalance?.balance < 5 && <div className="network-status">
            Store's SMS account balance is low. Please recharge to avoid problems sending SMS.
        </div>}
         
        <ManagerHeader navActive={true}/>
        {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
        {!tickets && <Spinner />}
        <div className="dash-container dash-container-right">
            {storeInfo && tickets &&
                <TicketTables  merchantKey={merchantKey} storeDetails={storeInfo} Tickets={ _.groupBy(tickets, 'queue_type_id')} StoreInfo={_.sortBy(storeInfo?.queue_options, ['prefix'])}/>
            }
        </div>

        <Footer float={true}/>
        </>
    )
}

export default withRouter (ManageHome)
