import React, { useState } from "react";
import { firestore } from "../../../../firebase";
import Loader from "react-loader-spinner";
import Popup from "reactjs-popup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import TinyUrl from "tinyurl";
import _ from "lodash";
import CountryCodes from '../../../../assets/lib/country.json'

const MoreSettingsPopUp = ({ storeInfo, merchantKey, moreSetting, setMoreSetting }) => {
  const moreForm = {
    store_logo: storeInfo.store_logo,
    store_favicon: storeInfo.store_favicon,
    store_default_country_code: storeInfo?.store_default_country_code || "SG",
    store_sub_heading: storeInfo?.store_sub_heading || "",
    store_footer_text: storeInfo?.store_footer_text || "",
    ticket_message: storeInfo?.ticket_message || "",
    now_serving_text: storeInfo?.now_serving_text || "Now Serving",
    action_button: {
      is_active: storeInfo?.action_button.is_active || false,
      link: storeInfo?.action_button.link || "",
      name: storeInfo?.action_button.name || "",
    },
    enable_sms_alerts: _.isBoolean(storeInfo?.enable_sms_alerts)
      ? storeInfo?.enable_sms_alerts
      : true,
  };

  const smsSetting = {
    enable_sms_by_default: storeInfo?.enable_sms_by_default || false,
    sms_template: {
      calling: "",
      no_show: "",
      feedback: "",
      reminder: "",
      ...storeInfo?.sms_template,
    },
    enable_feedback_sms: storeInfo?.enable_feedback_sms || false,
    enable_reminder_sms: storeInfo?.enable_reminder_sms || false,
    reminder_sms_queue_no_away: storeInfo?.reminder_sms_queue_no_away,
    feedback_sms_wait_duration: storeInfo?.feedback_sms_wait_duration || "",
    feedback_url: storeInfo?.feedback_url || "",
    feedback_url_shorten_url: storeInfo?.feedback_url_shorten_url || "",
  };
  
  const [moreFormValue, setMoreFormValue] = useState(moreForm);
  const [smsSettings, setSmsSettings] = useState(smsSetting);
  const [promotions, setPromotions] = useState(storeInfo.promotions?.length > 0 ? storeInfo.promotions : promotionsData)
  const [loading, setLoading] = useState(false);
  const [promotionErrors, setPromotionErrors] = useState([])
  const [fieldValidaton, setFieldValidation] = useState({});

  const _handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const docRef = firestore
      .collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`)
      .doc(merchantKey);

    await docRef.set(
      {
        ...moreFormValue,
      },
      { merge: true }
    );
    setLoading(false);
    setMoreSetting(false);
  };

  const _handleSubmitPromotions = async (e) => {
    setLoading(true);
    e.preventDefault();
    const docRef = firestore
      .collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`)
      .doc(merchantKey);

    await docRef.set(
      {
        promotions,
      },
      { merge: true }
    );
    setLoading(false);
    setMoreSetting(false);
  };


  const _handleSubmitSms = async (e) => {
    let textMatcher = /<([^%>]+)?>/g;
    setLoading(true);
    e.preventDefault();
    const settings = _.cloneDeep(smsSettings);

    if (
      smsSettings?.feedback_url &&
      smsSettings?.feedback_url !== storeInfo?.feedback_url
    ) {
      const feedback_url_shorten_url = await TinyUrl.shorten(
        `${smsSettings?.feedback_url}`
      ).then(function (res) {
        return res;
      });
      settings.feedback_url_shorten_url = feedback_url_shorten_url;
    }

    if (settings.sms_template?.feedback) {
      const params = settings.sms_template?.feedback.match(textMatcher);
      const feedback_param = params.includes("<Feedback_link>");
      if (!feedback_param) {
        setFieldValidation({ feedback: "<Feedback_link> Param is required." });
        setLoading(false);
        return;
      }
      const checkParam = _.difference(params, [
        "<Guest_name>",
        "<Feedback_link>",
        "<Queue_link>",
        "<Outlet_name>",
      ]);
      if (checkParam && checkParam.length > 0) {
        setFieldValidation({
          feedback: `${checkParam[0]} Param is Invalid in Feedback Template.`,
        });
        setLoading(false);
        return;
      }
    }

    if (settings.sms_template?.calling) {
      const params = settings.sms_template?.calling.match(textMatcher);
      const checkParam = _.difference(params, [
        "<Guest_name>",
        "<Queue_no>",
        "<Queue_link>",
        "<Outlet_name>",
      ]);
      if (checkParam && checkParam.length > 0) {
        setFieldValidation({
          calling: `${checkParam[0]} Param is Invalid in Feedback Template.`,
        });
        setLoading(false);
        return;
      }
    }

    if (settings.sms_template?.no_show) {
      const params = settings.sms_template?.no_show.match(textMatcher);
      const checkParam = _.difference(params, [
        "<Guest_name>",
        "<Queue_no>",
        "<Queue_link>",
        "<Outlet_name>",
      ]);
      if (checkParam && checkParam.length > 0) {
        setFieldValidation({
          no_show: `${checkParam[0]} Param is Invalid in Feedback Template.`,
        });
        setLoading(false);
        return;
      }
    }

    if (settings.sms_template?.reminder) {
      const params = settings.sms_template?.reminder.match(textMatcher);
      const checkParam = _.difference(params, [
        "<Guest_name>",
        "<No_of_queues_ahead>",
        "<Queue_link>",
        "<Outlet_name>",
      ]);
      if (checkParam && checkParam.length > 0) {
        setFieldValidation({
          no_show: `${checkParam[0]} Param is Invalid in Feedback Template.`,
        });
        setLoading(false);
        return;
      }
    }

    const docRef = firestore
      .collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`)
      .doc(merchantKey);

    await docRef.set(
      {
        ...settings,
      },
      { merge: true }
    );
    setLoading(false);
    setMoreSetting(false);
  };

  const updatePromo = (index, src, id) => {

    const data = _.cloneDeep(promotions)


    const item = {...data[index]};

    item.src = src;

    const fileType = src.split(/[#?]/)[0].split('.').pop().trim();

    const videoFormats = ['mp4', 'mov', 'mkv', 'wmv']
    const imageFormats = ['jpg', 'jpeg', 'png']

    let isError = false

    if(!fileType){
      isError =true
    }

    if(videoFormats.includes(fileType)){
      item.type = 'video';
      isError =false
    }else if (imageFormats.includes(fileType)){
      item.type = 'image';
      isError =false
    }else {
      isError =true
    }

    setPromotionErrors(isError ? [...promotionErrors, id] : promotionErrors.filter(ele => ele !== id))


    data[index] = item;

    setPromotions(data)


  }



  return (
    <Popup open={moreSetting} closeOnDocumentClick onClose={() => setMoreSetting(false)}>
      <div className="modal">
        <button
          style={{ zIndex: "99999" }}
          className="close"
          onClick={() => setMoreSetting(false)}
        >
          &times;
        </button>
        <Tabs>
          <TabList>
            <Tab>General Settings</Tab>
            <Tab>SMS Settings</Tab>
            <Tab>Promotions</Tab>
          </TabList>

          <TabPanel>
            <form onSubmit={_handleSubmit}>
              <div
                className="modal-container"
                style={{
                  padding: "5px 20px 0px 20px",
                  maxHeight: "70vh",
                  overflow: "auto",
                }}
              >
                <div className="input-boxes">
                  <div style={{ display: "flex", marginBottom: "1.6rem" }}>
                    <div className="input-box" style={{ width: "80%", marginBottom: 0 }}>
                      <label htmlFor="store_logo">STORE FAVICON</label>
                      <input
                        type="text"
                        placeholder="Add Logo URL"
                        id="store_logo"
                        className="name-input"
                        value={moreFormValue.store_favicon}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            store_favicon: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "3em",
                        maxHeight: "3em",
                      }}
                    >
                      {moreFormValue?.store_favicon && (
                        <img
                          src={moreFormValue?.store_favicon}
                          alt="logo"
                          style={{
                            width: "3em",
                            maxHeight: "3em",
                            objectFit: "contain",
                            marginLeft: "1rem",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div style={{ display: "flex", marginBottom: "1.6rem" }}>
                    <div className="input-box" style={{ width: "80%", marginBottom: 0 }}>
                      <label htmlFor="store_logo">STORE LOGO</label>
                      <input
                        type="text"
                        required
                        placeholder="Add Logo URL"
                        id="store_logo"
                        className="name-input"
                        value={moreFormValue.store_logo}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            store_logo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "3em",
                        maxHeight: "3em",
                      }}
                    >
                      {moreFormValue?.store_logo && (
                        <img
                          src={moreFormValue?.store_logo}
                          alt="logo"
                          style={{
                            width: "3em",
                            maxHeight: "3em",
                            objectFit: "contain",
                            marginLeft: "1rem",
                          }}
                        />
                      )}
                    </div>
                  </div>


                  <div
                          className="input-box"
                          style={{
                            padding: "3px 10px 1px 10px",
                            margin: 0,
                            marginBottom: "1rem",
                          }}
                        >
                          <label>
                            Store Default Country Code
                          </label>
                          <Select
                            menuColor="red"
                            defaultValue={
                              moreFormValue.store_default_country_code
                                ? CountryCodes.filter(
                                    (_) =>
                                      _.value === moreFormValue.store_default_country_code
                                  )
                                : []
                            }
                            styles={styleSelect}
                            options={CountryCodes}
                            onChange={({ value }) =>
                              setMoreFormValue({
                                ...moreFormValue,
                                store_default_country_code: value,
                              })
                            }
                          />
                    </div>

                    <div className="input-box" style={{  marginBottom: 0 , }}>
                      <label htmlFor="store_now_serving">NOW SERVING TEXT </label>
                      <input
                        type="text"
                        placeholder="Add Now Serving Text"
                        id="store_now_serving"
                        className="name-input"
                        value={moreFormValue.now_serving_text}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            now_serving_text: e.target.value,
                          })
                        }
                      />
                    </div>

                  <div className="input-boxes info-txt-box">
                    <div className="input-box welcome-txt-box" style={{ height: "7rem" }}>
                      <label htmlFor="welcome-txt">Welcome Text</label>
                      <textarea
                        style={{ height: "6.6rem" }}
                        className="name-input"
                        required
                        placeholder="Write Welcome Text in 180 characters"
                        maxLength="180"
                        id="welcome-txt"
                        type="textarea"
                        value={moreFormValue?.store_sub_heading}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            store_sub_heading: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  

                  <div className="input-boxes info-txt-box">
                    <div className="input-box welcome-txt-box" style={{ height: "6rem" }}>
                      <label htmlFor="welcome-txt">Footer Text </label>
                      <textarea
                        style={{ height: "5.6rem" }}
                        className="name-input"
                        required
                        placeholder="Write Welcome Text in 100 characters"
                        maxLength="100"
                        id="welcome-txt"
                        type="textarea"
                        value={moreFormValue?.store_footer_text}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            store_footer_text: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="input-boxes info-txt-box">
                    <div className="input-box Ticket-txt-box" style={{ height: "6rem" }}>
                      <label htmlFor="Ticket-txt">Ticket - Header Text</label>
                      <textarea
                        style={{ height: "5.6rem" }}
                        className="name-input"
                        required
                        placeholder="Write Welcome Text in 100 characters"
                        id="Ticket-txt"
                        type="textarea"
                        value={moreFormValue?.ticket_message}
                        onChange={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            ticket_message: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="check-box"
                    style={{ marginLeft: "0.5rem", marginBottom: "1.2rem" }}
                  >
                    <div className="round" style={{ width: "25px", height: "25px" }}>
                      <input
                        type="checkbox"
                        id="checkbox-sms"
                        checked={moreFormValue?.enable_sms_alerts}
                        onClick={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            enable_sms_alerts: e.target.checked,
                          })
                        }
                      />
                      <label htmlFor="checkbox-sms"></label>
                    </div>
                    <div
                      className="chechbox-name"
                      style={{ zIndex: 999, paddingLeft: "10px", fontSize: "0.9rem" }}
                    >
                      Enable SMS alerts
                    </div>
                  </div>
                  {/* Moved to SMS Setting Panel */}
                  {/* <div className="check-box" style={{marginLeft:"0.5rem", marginBottom:"1.2rem" }}>
                      <div className="round" style={{width:"25px", height:"25px"}}>
                          <input type="checkbox" id="checkbox-sms" checked={moreFormValue?.enable_sms_by_default} onClick={(e) => setMoreFormValue({
                              ...moreFormValue,
                              enable_sms_by_default: e.target.checked,
                            }) }   />
                          <label htmlFor="checkbox-sms"></label>
                      </div>
                      <div className="chechbox-name" style={{zIndex: 999, paddingLeft: "10px", fontSize: "0.9rem"}} >Enable SMS by default in Guest form</div>
                    </div>  */}

                  <div className="check-box" style={{ marginLeft: "0.5rem" }}>
                    <div className="round" style={{ width: "25px", height: "25px" }}>
                      <input
                        type="checkbox"
                        id="checkbox-order"
                        checked={moreFormValue?.action_button?.is_active}
                        onClick={(e) =>
                          setMoreFormValue({
                            ...moreFormValue,
                            action_button: {
                              ...moreFormValue.action_button,
                              is_active: e.target.checked,
                            },
                          })
                        }
                      />
                      <label htmlFor="checkbox-order"></label>
                    </div>
                    <div
                      className="chechbox-name"
                      style={{ zIndex: 999, paddingLeft: "10px", fontSize: "0.9rem" }}
                    >
                      Show Action Button
                    </div>
                  </div>

                  {moreFormValue?.action_button?.is_active && (
                    <>
                      <div className="input-box" style={{ marginTop: "1.5rem" }}>
                        <label htmlFor="store_action_btn">Action Button Text</label>
                        <input
                          type="text"
                          required
                          placeholder="Store Name"
                          id="store_action_btn"
                          className="name-input"
                          value={moreFormValue?.action_button?.name}
                          onChange={(e) =>
                            setMoreFormValue({
                              ...moreFormValue,
                              action_button: {
                                ...moreFormValue.action_button,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <div className="input-box">
                        <label htmlFor="store_act_link">Action Redirect URL</label>
                        <input
                          type="text"
                          required
                          placeholder="Store Name"
                          id="store_act_link"
                          className="name-input"
                          value={moreFormValue?.action_button?.link}
                          onChange={(e) =>
                            setMoreFormValue({
                              ...moreFormValue,
                              action_button: {
                                ...moreFormValue.action_button,
                                link: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div style={{ padding: "10px 20px 20px 20px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                >
                  <b>
                    {loading ? (
                      <Loader
                        type="ThreeDots"
                        color="white"
                        height={"12px"}
                        width={"40%"}
                        timeout={6000000}
                      />
                    ) : (
                      "SAVE"
                    )}
                  </b>
                </button>
              </div>
            </form>
          </TabPanel>

          <TabPanel>
            <form onSubmit={_handleSubmitSms}>
            {!moreFormValue.enable_sms_alerts  && <div className="overlay overlay-pop-up"> <h2>Enable SMS Alerts To Customize SMS Settings</h2></div>}
              <div
                className="modal-container"
                style={{
                  padding: "20px 20px 0px 20px",
                  maxHeight: "70vh",
                  overflow: "auto",
                }}
              >
                
                {moreFormValue.enable_sms_alerts && (
                  <>
                    <div
                      className="check-box"
                      style={{ marginLeft: "0.5rem", marginBottom: "1.2rem" }}
                    >
                      <div className="round" style={{ width: "25px", height: "25px" }}>
                        <input
                          type="checkbox"
                          id="checkbox-sms"
                          checked={smsSettings?.enable_sms_by_default}
                          onClick={(e) =>
                            setSmsSettings({
                              ...smsSettings,
                              enable_sms_by_default: e.target.checked,
                            })
                          }
                        />
                        <label htmlFor="checkbox-sms"></label>
                      </div>
                      <div
                        className="chechbox-name"
                        style={{ zIndex: 999, paddingLeft: "10px", fontSize: "0.9rem" }}
                      >
                        Enable SMS by default in Guest form
                      </div>
                    </div>

                    <div>
                      <p
                        style={{
                          fontWeight: "700",
                          fontSize: "1rem",
                          marginBottom: "0.2rem",
                        }}
                      >
                        SMS Reminders
                      </p>
                      <p style={{ marginBottom: "0.7rem", color: "#535353" }}>
                        Send a reminder SMS to the customer notifying no. of queues away
                        from being called
                      </p>

                      <hr style={{ marginBottom: "0.8rem" }} />

                      <div
                        className="check-box"
                        style={{
                          marginLeft: "0.5rem",
                          marginBottom: "1.4rem",
                          marginTop: "1rem",
                        }}
                      >
                        <div className="round" style={{ width: "25px", height: "25px" }}>
                          <input
                            type="checkbox"
                            id="checkbox-sms-reminder"
                            checked={smsSettings?.enable_reminder_sms}
                            onClick={(e) =>
                              setSmsSettings({
                                ...smsSettings,
                                enable_reminder_sms: e.target.checked,
                              })
                            }
                          />
                          <label htmlFor="checkbox-sms-reminder"></label>
                        </div>
                        <div
                          className="chechbox-name"
                          style={{ zIndex: 999, paddingLeft: "10px", fontSize: "0.9rem" }}
                        >
                          Enable Reminder SMS
                        </div>
                      </div>
                    </div>

                    {smsSettings.enable_reminder_sms && (
                      <>
                        <div
                          className="input-box"
                          style={{
                            padding: "3px 10px 1px 10px",
                            margin: 0,
                            marginBottom: "1rem",
                          }}
                        >
                          <label>
                            Send SMS reminder once user is following queue no. from being called
                          </label>
                          <Select
                            menuColor="red"
                            defaultValue={
                              smsSettings.reminder_sms_queue_no_away
                                ? queue_no_away_list.filter(
                                    (_) =>
                                      _.value === smsSettings.reminder_sms_queue_no_away
                                  )
                                : []
                            }
                            styles={styleSelect}
                            options={queue_no_away_list}
                            onChange={({ value }) =>
                              setSmsSettings({
                                ...smsSettings,
                                reminder_sms_queue_no_away: value,
                              })
                            }
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              margin: 0,
                              width: 0,
                              position: "absolute",
                            }}
                            value={smsSettings.reminder_sms_queue_no_away}
                            required
                          />
                        </div>

                      </>
                    )}
                  </>
                )}

                <div>
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "1rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Feedback SMS
                  </p>
                  <p style={{ marginBottom: "0.7rem", color: "#535353" }}>
                    Schedule SMS feedback once guest are 'SEATED'
                  </p>

                  <hr style={{ marginBottom: "0.8rem" }} />

                  <div
                    className="check-box"
                    style={{
                      marginLeft: "0.5rem",
                      marginBottom: "1.4rem",
                      marginTop: "1rem",
                    }}
                  >
                    <div className="round" style={{ width: "25px", height: "25px" }}>
                      <input
                        type="checkbox"
                        id="checkbox-sms-feedback"
                        checked={smsSettings?.enable_feedback_sms}
                        onClick={(e) =>
                          setSmsSettings({
                            ...smsSettings,
                            enable_feedback_sms: e.target.checked,
                          })
                        }
                      />
                      <label htmlFor="checkbox-sms-feedback"></label>
                    </div>
                    <div
                      className="chechbox-name"
                      style={{ paddingLeft: "10px", fontSize: "0.9rem" }}
                    >
                      Enable Feedback SMS
                    </div>
                  </div>

                  {smsSettings.enable_feedback_sms && (
                    <>
                      <div
                        className="input-box"
                        style={{
                          padding: "3px 10px 1px 10px",
                          margin: 0,
                          marginBottom: "1rem",
                        }}
                      >
                        <label>
                          Wait after guest is 'seated' to send SMS Feedback link
                        </label>
                        <Select
                          menuColor="red"
                          defaultValue={
                            smsSettings.feedback_sms_wait_duration
                              ? duration.filter(
                                  (_) =>
                                    _.value === smsSettings.feedback_sms_wait_duration
                                )
                              : []
                          }
                          styles={styleSelect}
                          options={duration}
                          onChange={({ value }) =>
                            setSmsSettings({
                              ...smsSettings,
                              feedback_sms_wait_duration: value,
                            })
                          }
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            margin: 0,
                            width: 0,
                            position: "absolute",
                          }}
                          value={smsSettings.feedback_sms_wait_duration}
                          required
                        />
                      </div>

                      <div className={`input-box`} style={{ marginTop: "1.5rem" }}>
                        <label htmlFor="feedback_url_field">Feedback URL</label>
                        <input
                          type="text"
                          placeholder="Add Shortened Feedback URL"
                          id="feedback_url_field"
                          className="name-input"
                          value={smsSettings?.feedback_url || ""}
                          required
                          onChange={(e) =>
                            setSmsSettings({
                              ...smsSettings,
                              feedback_url: e.target.value,
                            })
                          }
                        />
                      </div>
                    </>
                  )}

                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "1rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Customize SMS Template
                  </p>
                  <p style={{ marginBottom: "0.7rem", color: "#535353" }}>
                    Customize SMS template with pre-defined parameters
                  </p>
                  <hr style={{ marginBottom: "0.8rem" }} />
                  <p
                    style={{
                      marginLeft: "1rem",
                      marginBottom: "0.5rem",
                      fontWeight: "600",
                      fontSize: ".9rem",
                    }}
                  >
                    Parameter Supported
                  </p>
                  <ul
                    style={{ marginLeft: "3rem", fontWeight: "500", fontSize: ".9rem" }}
                  >
                    <li>
                      {"<Guest_name>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small>: John Doe</small>
                      </span>
                    </li>
                    <li>
                      {"<Queue_no>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small>: B001</small>
                      </span>{" "}
                    </li>
                    <li>
                      {"<Queue_link>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small> : {storeInfo.store_short_url}</small>
                      </span>{" "}
                    </li>
                    <li>
                      {"<Outlet_name>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small>: {storeInfo.store_name}</small>{" "}
                      </span>{" "}
                    </li>
                    <li>
                      {"<Feedback_url>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small>
                          : {storeInfo?.feedback_url_shorten_url || "'www.feedback.org'"}
                        </small>
                      </span>{" "}
                    </li>
                    <li>
                      {"<No_of_queues_ahead>"}{" "}
                      <span
                        style={{ color: "#535353", fontWeight: "400", fontSize: ".8rem" }}
                      >
                        {" "}
                        <small>
                          : {storeInfo?.reminder_sms_queue_no_away || smsSettings.reminder_sms_queue_no_away}
                        </small>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
                <div className="input-boxes">
                  <div className="input-boxes info-txt-box">
                    <div
                      className={`input-box welcome-txt-box mb-2
                            ${fieldValidaton["calling"] ? "err-form mb-3" : ""}`}
                      style={{ height: "7rem" }}
                    >
                      <label htmlFor="calling-txt">CALLING Template</label>
                      <textarea
                        style={{ height: "6.6rem" }}
                        className="name-input"
                        required
                        placeholder="Write CALLING SMS Text in 160 characters"
                        maxLength="160"
                        id="calling-txt"
                        type="textarea"
                        value={smsSettings.sms_template["calling"]}
                        onChange={(e) =>
                          setSmsSettings({
                            ...smsSettings,
                            sms_template: {
                              ...smsSettings.sms_template,
                              calling: e.target.value,
                            },
                          })
                        }
                      />
                      {smsSettings.sms_template["calling"]?.trim() === "" && (
                        <p
                          className="link-p"
                          onClick={(e) => {
                            setSmsSettings({
                              ...smsSettings,
                              sms_template: {
                                ...smsSettings.sms_template,
                                calling: smsDefaultTemplate.calling,
                              },
                            });
                          }}
                        >
                          <u>Add Default 'Calling' template</u>
                        </p>
                      )}
                      {fieldValidaton["calling"] && (
                        <p className="err-message">{fieldValidaton["calling"]}</p>
                      )}
                    </div>
                  </div>

                  <div className="input-boxes info-txt-box">
                    <div
                      className={`input-box welcome-txt-box  mb-2
                            ${fieldValidaton["no_show"] ? "err-form mb-3" : ""}`}
                      style={{ height: "7rem" }}
                    >
                      <label htmlFor="no-show">NO SHOW Template</label>
                      <textarea
                        style={{ height: "6.6rem" }}
                        className="name-input"
                        required
                        placeholder="Write NO SHOW SMS Text in 160 characters"
                        maxLength="160"
                        id="no-show-txt"
                        type="textarea"
                        value={smsSettings.sms_template["no_show"]}
                        onChange={(e) =>
                          setSmsSettings({
                            ...smsSettings,
                            sms_template: {
                              ...smsSettings.sms_template,
                              no_show: e.target.value,
                            },
                          })
                        }
                      />
                      {smsSettings.sms_template["no_show"]?.trim() === "" && (
                        <p
                          className="link-p"
                          onClick={(e) => {
                            setSmsSettings({
                              ...smsSettings,
                              sms_template: {
                                ...smsSettings.sms_template,
                                no_show: smsDefaultTemplate.no_show,
                              },
                            });
                          }}
                        >
                          <u>Add Default 'No Show' template</u>
                        </p>
                      )}
                      {fieldValidaton["no_show"] && (
                        <p className="err-message">{fieldValidaton["no_show"]}</p>
                      )}
                    </div>
                  </div>

                  {smsSettings.enable_feedback_sms && (
                    <div className="input-boxes info-txt-box">
                      <div
                        className={`input-box welcome-txt-box 
                            ${fieldValidaton["feedback"] ? "err-form mb-3" : ""}`}
                        style={{ height: "7rem" }}
                      >
                        <label htmlFor="feedback">Feedback SMS Template</label>
                        <textarea
                          style={{ height: "6.6rem" }}
                          className="name-input"
                          required
                          placeholder="Write Feedback SMS Text in 160 characters"
                          maxLength="160"
                          id="feedback-txt"
                          type="textarea"
                          value={smsSettings.sms_template["feedback"]}
                          onChange={(e) =>
                            setSmsSettings({
                              ...smsSettings,
                              sms_template: {
                                ...smsSettings.sms_template,
                                feedback: e.target.value,
                              },
                            })
                          }
                        />
                        {smsSettings.sms_template["feedback"]?.trim() === "" && (
                          <p
                            className="link-p"
                            onClick={(e) => {
                              setSmsSettings({
                                ...smsSettings,
                                sms_template: {
                                  ...smsSettings.sms_template,
                                  feedback: smsDefaultTemplate.feedback,
                                },
                              });
                            }}
                          >
                            <u>Add Default 'Feedback' template</u>
                          </p>
                        )}
                        {fieldValidaton["feedback"] && (
                          <p className="err-message">{fieldValidaton["feedback"]}</p>
                        )}
                      </div>
                    </div>
                  )}
                  {smsSettings.enable_reminder_sms && (
                    <div className="input-boxes info-txt-box">
                      <div
                        className={`input-box welcome-txt-box 
                            ${fieldValidaton["feedback"] ? "err-form mb-3" : ""}`}
                        style={{ height: "7rem" }}
                      >
                        <label htmlFor="reminder-txt">Reminder SMS Template</label>
                        <textarea
                          style={{ height: "6.6rem" }}
                          className="name-input"
                          required
                          placeholder="Write Reinder SMS Text in 160 characters"
                          maxLength="160"
                          id="reminder-txt"
                          type="textarea"
                          value={smsSettings.sms_template["reminder"]}
                          onChange={(e) =>
                            setSmsSettings({
                              ...smsSettings,
                              sms_template: {
                                ...smsSettings.sms_template,
                                reminder: e.target.value,
                              },
                            })
                          }
                        />
                        {smsSettings.sms_template["reminder"]?.trim() === "" && (
                          <p
                            className="link-p"
                            onClick={(e) => {
                              setSmsSettings({
                                ...smsSettings,
                                sms_template: {
                                  ...smsSettings.sms_template,
                                  reminder: smsDefaultTemplate.reminder,
                                },
                              });
                            }}
                          >
                            <u>Add Default 'Reminder SMS' template</u>
                          </p>
                        )}
                        {fieldValidaton["feedback"] && (
                          <p className="err-message">{fieldValidaton["reminder"]}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {moreFormValue.enable_sms_alerts && (
                <div style={{ padding: "10px 20px 20px 20px" }}>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                  >
                    <b>
                      {loading ? (
                        <Loader
                          type="ThreeDots"
                          color="white"
                          height={"12px"}
                          width={"40%"}
                          timeout={6000000}
                        />
                      ) : (
                        "SAVE"
                      )}
                    </b>
                  </button>
                </div>
              )}
            </form>
          </TabPanel>
        
          <TabPanel>
            <form onSubmit={_handleSubmitPromotions}>
              <div
                className="modal-container"
                style={{
                  padding: "5px 20px 0px 20px",
                  maxHeight: "70vh",
                  overflow: "auto",
                }}
              >

                <div className='flex items-center space-between' style={{padding: "1rem 0"}}>
                  <div>
                    <p
                      style={{
                            fontWeight: "700",
                            fontSize: "1rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          Promotions Ads 
                    </p>
                    <p style={{ marginBottom: "0", color: "#535353" }}>
                      Add Promotional video's and images to be  displayed on <br /> Queue Display Page. 
                      {/* <br /><i>Supported media formats images: [jpg,jpeg,png]  and video: [mp4]</i>  */}
                    </p>
                  </div>
                  <button className="btn btn-primary " type='button' onClick={() => setPromotions([...promotions, {id:Math.floor(1000 + Math.random() * 9000), type: "", src:"" }])}>Add More</button>
                
                
                </div>
                <hr style={{ marginBottom: "0.8rem" }} />

                        <p><i>Supported media formats images: [jpg,jpeg,png]  and video: [mp4]</i> </p>

                <div className="input-boxes">
                    
                  {promotions?.map((promo, index) => 

                      <div style={{marginBottom: "1rem"}}>
                        <div className="flex items-center  space-between" >
                        <div className="input-box" key={promo?.id} style={{margin:0, width: "95%"}} >
                        <label htmlFor={`promo-${promo?.id}`}>Promotion {index +1 }</label>
                        <input
                          type="url"
                          placeholder="Promotion Media URL"
                          id={`promo-${promo?.id}`}
                          className="name-input"
                          value={promo.src}
                          onChange={(e) => updatePromo(index, e.target.value, promo?.id)}
                          required
                        />
                        
                      </div>
                      <button type='button' className="btn-rounded" onClick={() => setPromotions(promotions?.filter(ele => ele.id !== promo?.id))}>X</button>
                      </div>
                      {promotionErrors.includes(promo.id) && <p style={{color:"red", marginLeft:"1rem"}}> <b>Invalid File Type</b> </p>}
                      </div>
                    )}

                </div>  
              
              </div>
              
              
              <div style={{ padding: "10px 20px 20px 20px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  disabled={promotionErrors?.length>0 ? true :false}
                >
                  <b>
                    {loading ? (
                      <Loader
                        type="ThreeDots"
                        color="white"
                        height={"12px"}
                        width={"40%"}
                        timeout={6000000}
                      />
                    ) : (
                      "SAVE"
                    )}
                  </b>
                </button>
              </div>
            </form>
          </TabPanel>
        
        </Tabs>
      </div>
    </Popup>
  );
};

export default MoreSettingsPopUp;

const styleSelect = {
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    "&:hover": {
      backgroundColor: "var(--color_primary)",
      color: "whitesmoke",
    },
    backgroundColor: isSelected ? "var(--color_primary)" : "whitesmoke",
    color: isSelected ? "whitesmoke" : "black",
  }),
};

const duration = [
  { value: "30m", label: "30 Minutes" },
  { value: "45m", label: "45 Minutes" },
  { value: "1h", label: "1 Hour" },
  { value: "2h", label: "2 Hours" },
  { value: "3h", label: "3 Hours" },
  { value: "4h", label: "4 Hours" },
];

const queue_no_away_list = [
  { value: 1, label: "1 Queue no. away" },
  { value: 2, label: "2 Queue no. away" },
  { value: 3, label: "3 Queue no. away" },
  { value: 4, label: "4 Queue no. away" },
  { value: 5, label: "5 Queue no. away" },
];

const smsDefaultTemplate = {
  calling:
    "Hi <Guest_name>, Your queue no.'<Queue_no>' is now being called, please visit the counter to be seated. Thanks, <Outlet_name>",
  no_show:
    "Hi <Guest_name>, Your queue no. '<Queue_no>' was marked 'No show'. Please visit <Queue_link> to join again. Thanks, <Outlet_name>",
  feedback: "Hi <Guest_name>, Please leave us your feedback <Feedback_link>",
  reminder: "Hi <Guest_name>, There are <No_of_queues_ahead> queue nos. ahead of you now, please proceed to the restaurant if you are not nearby. Thanks, <Outlet_name>"
};


const promotionsData = [
  {
    type: "video",
    src: "",
    id: Math.floor(1000 + Math.random() * 9000)
  },
]