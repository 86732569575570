import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { firestore } from '../../../../firebase'
import firebase from "firebase/app";
import { writeInfoLog } from '../../../../helpers/logger';


// const style = {
//     ticketCol: {margin: "30px", fontFamily: "'Nunito Sans', sans-serif", fontWeight: 800},
// }

const TicketTables = ({ Tickets, StoreInfo, merchantKey, storeDetails }) => {

    const date = new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-')

    const sendSms = _.isBoolean(storeDetails.enable_sms_alerts) ? storeDetails.enable_sms_alerts : true;

    const enableReminder = _.isBoolean(storeDetails.enable_reminder_sms) ? storeDetails.enable_reminder_sms : false

    const reminder_queue_away = (sendSms && enableReminder) ? storeDetails.reminder_sms_queue_no_away : 0

    const triggerSMS = async (to, template) => {

        if (sendSms) {
            const payload = {
                user: { ...to },
                template
            }

            Promise.all([
                axios.post(`${process.env.REACT_APP_SEND_SMS_API_URL}/${merchantKey}/send-sms`,
                    payload)
                // axios.post(`https://asia-southeast2-tabsquare-crm.cloudfunctions.net/smartq_apis_dev/api/v1/store/${merchantKey}/send-whatsapp`,
                // payload)
            ])
        }
        return;

    }

    const triggerFeedback = async (to) => {
        if (sendSms) {
            const payload = {
                user: { ...to },
                template: "FEEDBACK"
            }


            Promise.all([
                axios.post(`${process.env.REACT_APP_SEND_SMS_API_URL}/${merchantKey}/feedback-trigger`,
                    payload)
                // axios.post(`https://asia-southeast2-tabsquare-crm.cloudfunctions.net/smartq_apis_dev/api/v1/store/${merchantKey}/send-whatsapp`,
                // payload)
            ])
        }

    }

    const hanldeCallNext = async (ticket) => {
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).doc(ticket?.id);
        const userActionTime = new Date();
        const timeAction = userActionTime.getTime();
        await docRef.set({
            status: 'CALLING',
            call_time: timeAction,
            updated_at: timeAction,
            sms_sent: ticket.send_sms && sendSms ? firebase.firestore.FieldValue.increment(1) : 0
        }, { merge: true });

        writeInfoLog(` callNext | merchantKey: ${merchantKey} | ticketNo: ${ticket?.id || ""} | actionDate: ${userActionTime} | actionTime: ${timeAction}`);

        if (ticket.send_sms && sendSms) {
            await triggerSMS({ name: ticket.customer_name, phone_number: ticket.customer_phone, ticket_no: ticket?.id }, "CALLING")
        }
        const queue_type = ticket?.queue_type_id


        if (reminder_queue_away && Tickets[queue_type] && Tickets[queue_type]?.length > 0) {
            const currentCalling = [...Tickets[queue_type].filter(x => x.status === "CALLING"), { ...ticket, status: "CALLING" }]
            const currentInQueue = Tickets[queue_type].filter(x => x.id !== ticket.id && x.status === "IN_QUEUE") || []

            const allTickets = [...currentCalling, ...currentInQueue]

            const reminderTicket = allTickets[reminder_queue_away]

            const allRemindersSent = JSON.parse(localStorage.getItem("reminder_tickets")) || []


            if (reminderTicket && !allRemindersSent?.includes(reminderTicket?.id)) {
                // console.log("Sent Reminder SMS ==>", reminderTicket?.id)

                localStorage.setItem("reminder_tickets", JSON.stringify([...allRemindersSent, reminderTicket?.id]))

                await triggerSMS({ name: reminderTicket.customer_name, phone_number: reminderTicket.customer_phone, no_of_queues_ahead: reminder_queue_away }, "REMINDER")

                const reminderDoc = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).doc(reminderTicket?.id);
                await reminderDoc.set({
                    updated_at: new Date().getTime(),
                    sms_sent: ticket.send_sms && sendSms ? firebase.firestore.FieldValue.increment(1) : 0
                }, { merge: true });

            }


        }

    }

    const handleTicketStatus = async (ticket, status) => {
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).doc(ticket?.id);
        const userActionTime = new Date();
        const timeAction = userActionTime.getTime();
        if (ticket.send_sms && status === "NO_SHOW") {
            writeInfoLog(` noShowClicked | merchantKey: ${merchantKey} | ticketNo: ${ticket?.id || ""} | actionDate: ${userActionTime} | actionTime: ${timeAction} `);
            await docRef.set({
                status: status,
                updated_at: timeAction,
                sms_sent: firebase.firestore.FieldValue.increment(1)

            }, { merge: true });
        } else {
            writeInfoLog(` seatedClicked | merchantKey: ${merchantKey} | ticketNo: ${ticket?.id || ""} | actionDate: ${userActionTime} | actionTime: ${timeAction} `);
            await docRef.set({
                status: status,
                updated_at: timeAction,
            }, { merge: true });
        }

        if (ticket.send_sms && status === "NO_SHOW" && sendSms) {
            await triggerSMS({ name: ticket.customer_name, phone_number: ticket.customer_phone, ticket_no: ticket?.id }, "NO_SHOW")
        }

        if (status === 'SEATED') {

            if (storeDetails.enable_feedback_sms && sendSms) {

                await triggerFeedback({ name: ticket.customer_name, phone_number: ticket.customer_phone })
                await docRef.set({
                    sms_sent: firebase.firestore.FieldValue.increment(1)

                }, { merge: true });
            }

        }

        const allRemindersSent = JSON.parse(localStorage.getItem("reminder_tickets")) || []

        if (allRemindersSent) {
            const arr = allRemindersSent.filter(x => x !== ticket?.id)
            localStorage.setItem("reminder_tickets", JSON.stringify(arr))
        }


    }

    return (
        <div className="table-wrap">
            {
                StoreInfo && StoreInfo.map((store, key) => {

                    return (

                        <div className="queue-type-row" id={store?.id} key={store?.id}>
                            <div className="queue-top-row">
                                <div className="calling-box">
                                    {_.findLast(Tickets[store.prefix], { "status": "CALLING" })?.id ? (
                                        <>
                                            <small>{storeDetails?.now_serving_text || "Now Serving"}</small>
                                            <h1>{(_.findLast(Tickets[store.prefix], { "status": "CALLING" }))?.id}</h1>
                                        </>
                                    ) : (
                                        <>
                                            <small style={{ color: 'red' }}>{store.is_active ? "No Active Tickets" : 'Queue is Paused'}</small>
                                            <h1 style={{ color: 'red' }}>―</h1>
                                        </>
                                    )}

                                </div>
                                <div className="queue-call-next">
                                    <h3 style={{ margin: 0 }}>{store.min_pax}-{store.max_pax} PAX</h3>
                                    <button className="btn btn-primary" disabled={(_.findIndex(Tickets[store.prefix], { "status": "IN_QUEUE" }) >= 0) ? false : true} onClick={() => hanldeCallNext((_.find(Tickets[store.prefix], { "status": "IN_QUEUE" })))}><strong>CALL NEXT</strong></button>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Queue No.</th>
                                        <td>Guest</td>
                                        <td>Queue time</td>
                                        <td align={'center'}>Call Time </td>
                                        <td width={"15%"} align={'center'}>NO SHOW</td>
                                        <td width={"15%"} align={'center'}>SEATED</td>
                                        <td width={"15%"} align={'center'}>Action</td>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Tickets[store.prefix] && Tickets[store.prefix]?.filter(etick => etick.status === 'CALLING')?.map((tick, k) => (
                                        <>
                                            {tick.status === 'CALLING' &&

                                                <tr id={k} key={tick?.id}>
                                                    <td><span><b>{tick.id}</b></span> <span>{tick?.no_guests ? tick?.no_guests + " Guest" : store.name}</span> </td>
                                                    <td className="td-name"><span>{tick.customer_name}</span><span>{tick.customer_phone}</span> </td>
                                                    <td className="td-name">{tick?.created_at &&
                                                        <>
                                                            <span>{new Date(+tick.created_at).toLocaleTimeString([], { timeStyle: 'short' })}</span>
                                                            <span>{new Date(+tick.created_at).toLocaleDateString()}</span>
                                                        </>}</td>
                                                    <td align={'center'} className="td-name">{tick?.call_time &&
                                                        <span>{new Date(+tick.call_time).toLocaleTimeString([], { timeStyle: 'short' })}</span>}</td>
                                                    <td align={'center'}><button onClick={() => handleTicketStatus(tick, "NO_SHOW")} className="btn btn-danger btn-sm">NO SHOW</button></td>
                                                    <td align={'center'}><button onClick={() => handleTicketStatus(tick, "SEATED")} className="btn btn-sucess btn-sm" >SEATED</button></td>
                                                    <td align={'center'}>-</td>
                                                </tr>
                                            }
                                        </>

                                    ))
                                    }


                                    {Tickets[store.prefix] && Tickets[store.prefix]?.filter(etick => etick.status !== 'CALLING')?.map((tick, k) => (
                                        <>
                                            {tick.status === 'IN_QUEUE' &&

                                                <tr id={k} key={tick?.id}>
                                                    <td><span><b>{tick.id}</b></span> <span>{tick?.no_guests ? tick?.no_guests + " Guest" : store.name}</span> </td>
                                                    <td className="td-name"><span>{tick.customer_name}</span> <span>{tick.customer_phone}</span> </td>
                                                    <td className="td-name">{tick?.created_at &&
                                                        <>
                                                            <span>{new Date(+tick.created_at).toLocaleTimeString([], { timeStyle: 'short' })}</span>
                                                            <span>{new Date(+tick.created_at).toLocaleDateString()}</span>
                                                        </>}</td>
                                                    <td align={'center'} >-</td>
                                                    <td align={'center'}>-</td>
                                                    <td align={'center'}>-</td>
                                                    <td><button onClick={() => hanldeCallNext(tick)} className="btn btn-primary btn-sm" >Call Now</button></td>
                                                </tr>
                                            }
                                        </>

                                    ))
                                    }

                                    {!Tickets[store.prefix] &&
                                        <tr key={"999"}>
                                            <td colSpan="6" align="center">No Active Queue No.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <hr />

                            {/* {Tickets[store.prefix] && Tickets[store.prefix].map((tick) => {
                            return (
                               <>
                               <div>    
                               </div>
                                {tick.status === 'IN_QUEUE' && <div className="ticket-in-queue"><h1>{tick.id}</h1></div> }
                               </>
                            )
                        })} */}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TicketTables
