import React from 'react';
import "./index.css"

const Location = ({name}) => {
    return (
    <h3 className="location">{name}</h3>
    )
}

export default Location
