import React, { useState } from 'react'
import { withRouter } from 'react-router'
import Header from '../Header'
import Location from '../home/Location'
import QueueTicketWelcome from './QueueTicketWelcome'
import QueueCard from './QueueCard'
import QueueTicketTimeCall from './QueueTicketTimeCall'
import { useCollectionData,useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import "./container.css";
import { firestore } from '../../../firebase'
import Spinner from '../../../components/Spinner'
import SharePopup from '../.././../components/sharePopup'
import changeSettings from '../../../helpers/changeSettings'
import Footer from '../../../components/Footer'
import isUrl from 'is-url'
import CancelPopup from '../../../components/cancelPopup'
import { writeInfoLog } from '../../../helpers/logger'

const TicketInfo = ({history,match}) => {
    const query = new URLSearchParams(window.location.search);
    const sharingId = query.get('sharing')

    const [loading, setLoading] = useState(false)
    const [shareModal, setShareModal] = useState(false)
    const [exitModal, setExitModal] = useState(false)

    const slug = match.params.slug 
    const ticketInfo = JSON.parse(localStorage.getItem("ticket_info"))
    const urlPath = window.location.pathname.split('/')[1]
    const date = new Date().toISOString().replace(/T.*/,'').split('-').reverse().join('-')
    if(!sharingId && !ticketInfo) {
        history.push(`/${urlPath}`)
    }

    const [storeSnap] = useCollectionDataOnce(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`).where("store_slug", '==', slug), {idField: "id"},
      );
    
    const storeInfo = storeSnap && storeSnap[0]
    
    changeSettings(storeInfo)

      const [ticketSnap, ticketLoading] = useCollectionData(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id|| "-"}/${date}`).where("sharing_id", '==', ticketInfo?.sharing_id || sharingId), {idField: "id"},
      );
    
    const ticket = ticketSnap && ticketSnap[0]

    if(ticketSnap && ticketSnap?.length <= 0) {
        localStorage.clear()
        history.push(`/${urlPath}`)
    }

    if(ticket && (ticket.status === 'NO_SHOW' || ticket.status === 'SEATED' || ticket.status === 'EXIT_QUEUE')) {
        localStorage.setItem("ticket_info", JSON.stringify({ tick_no: ticket?.id, ...ticket }))
    }

    const [calling] = useCollectionData(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id|| "-"}/${date}`)
        .where("queue_type_id", "==", ticket?.queue_type_id || "-")
        .where("status", "==", "CALLING")
        , {idField:"id"}
    );

    const handleExit = async() => {
        setLoading(true)
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id|| "-"}/${date}/`).doc(ticketInfo.tick_no);
        const userActionTime = new Date();
        const timeAction = userActionTime.getTime();
        writeInfoLog(`exitQueue | merchantKey : ${storeInfo?.id|| "-"} | ticketNo: ${ticketInfo.tick_no} | actionDate: ${userActionTime} | actionTime: ${timeAction} `, docRef);
        await docRef.set({
            status: 'EXIT_QUEUE',
            updated_at: timeAction
        }, {merge:true});
        localStorage.clear()
        setLoading(false)
        history.push(`/${storeInfo.store_slug}`)
    }



    if(ticket && ticket?.status === "EXIT_QUEUE"){
        localStorage.clear()
        history.push(`/${storeInfo.store_slug}`)
    }


    // const handleShare = () => {
    //     var token = jwt.sign(ticketInfo, 'smart-Q');
    //     console.log(token)
    // }


    const isTicketExpired = ticket?.status === "IN_QUEUE" || ticket?.status === "CALLING" ? true :false ;

    const handleShare = () => {

        if (navigator.share) { 
            navigator.share({
               title: `Tabsquare SmartQ`,
               url: `${window.location.href}?sharing=${ticket?.sharing_id}`
             }).then(() => {
               console.log('Thanks for sharing!');
             })
             .catch(console.error);
             } else {
                 setShareModal(true)
             }
    }

    
    return (
        <div style={{height:"100vh", backgroundColor:"white" }}>
            <Header />
            {ticketLoading || loading ?  <Spinner />:(
                <main className="container">
                    {shareModal && <SharePopup url={`${window.location.href}?sharing=${ticket?.sharing_id}`} closeModal={(e) => setShareModal(e)}/>}
                    {exitModal && <CancelPopup handleAction={handleExit} closeModal={(e) => setExitModal(e)} > </CancelPopup>}
                    <div className="display-queue-logo" style={{padding:0}}>
                            {storeInfo?.store_logo &&  <img src={storeInfo?.store_logo} alt={storeInfo?.store_name} style={{marginRight: "0.5rem",height:"40px",objectFit: "cover" }}/>}
                            
                            <Location name={storeInfo?.store_name} />
                        </div>
                {
                    isTicketExpired ? (
                       <>
                        <QueueTicketWelcome
                        name={sharingId ? "" : ticket?.customer_name}
                        content={storeInfo?.ticket_message} />
                        <QueueCard tick_no={ticket?.id} ticket_type={ticket?.no_guests ? ticket?.no_guests + ' Guests' : ticket?.queue_type}/>
                        <QueueTicketTimeCall store_meta={storeInfo} created_at={ticket?.created_at} callingId={calling ? calling[+calling?.length - 1]?.id :  "-" }/>
                        {storeInfo?.action_button?.is_active && isUrl(storeInfo?.action_button?.link) &&
                        <>
                        <a href={storeInfo?.action_button?.link} target="_blank" rel="noreferrer" className="button" style={{textDecoration:"none", textAlign:"center", padding: "0.35rem"}}>
                            {storeInfo?.action_button?.name || "VIEW MENU AND PRE-ORDER"} </a>
                        <div style={{marginBottom:  sharingId ? "4rem" : "20px"}} className="button-margin"></div>
                        </>
                        }
                        {!sharingId && 
                        <>
                        <button  className="button" onClick={handleShare}>SHARE QUEUE DETAILS</button>
                        <div className="button-margin"></div>
                        <button  className="button" style={{marginBottom:"4rem"}} onClick={() => setExitModal(true)}>EXIT QUEUE</button>
                        <div className="button-margin" ></div>
                        </>
                        }
                        
                       </>
                    ): (
                        <>
                        <QueueTicketWelcome
                        name={sharingId ? "" : ticket?.customer_name}
                        content={ticket?.status === "SEATED" ? "Your Queue no. has been marked as ‘Seated’": "Your Queue no. has been marked as ‘No Show’"}
                        morecontent={ticket?.status === "SEATED" ? "Thank you for dining with us today!" : "Please scan QR code again if you need a new one."} />
                        <QueueCard color_code={ticket?.status === "SEATED" ? "green" : ""} expiredTicket={true} tick_no={ticket?.id} ticket_type={ticket?.no_guests ? ticket?.no_guests + ' Guests' : ticket?.queue_type} />
                        <div className="button-margin"></div>
                        {/* {ticket?.status !== 'SEATED' && 
                            <>
                                <Link style={{textDecoration: "none", textAlign:"center"}} className="button" to={`/${urlPath}`} onClick={() => localStorage.clear()}>GET NEW QUEUE NUMBER</Link>
                                <div className="button-margin"></div> 
                             </>
                        
                        } */}
                        </>
                    )
                }
            </main>
            )}
            <Footer float={true} imgWidth="10rem" />
        </div>
    )
}

export default withRouter (TicketInfo)
