

const changeSettings = (storeInfo, type) => {
    if(storeInfo?.theme){
        Object.keys(storeInfo.theme).forEach((key) => {
            document.body.style.setProperty(`--${key}`, storeInfo.theme[key])
        })
    }

    if(storeInfo?.store_favicon){
        const favi = document.getElementById("favicon");
        favi.href = storeInfo.store_favicon
    }
    if(storeInfo?.store_name) {
        const title = document.getElementById("smartq-title");
        title.innerHTML =  `${type? type + " | " : ""}${storeInfo?.store_name} | Tabsquare - SmartQ`
    }
   

}


export default changeSettings
