import React from 'react'
import ManagerHeader from '../../../components/ManageHeaders'
import _ from 'lodash';
import TicketColumns from "./components/TicketColumns"
import DisplayLandscape from "./components/DisplayLandscape"
import Spinner from '../../../components/Spinner';
import { withRouter } from 'react-router'
import Footer from '../../../components/Footer';
import DisplayPortrait from './components/DisplayPortrait';
import { useLocation } from "react-router-dom";

const DisplayQueue = ({merchantKey,storeInfo, tickets}) => {


    const search = useLocation().search;
    const displayType = new URLSearchParams(search).get("displayType");
    

    switch (displayType) {
        case "v2-landspace":
            return (
                <>
                <ManagerHeader navActive={false}/>
                {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
                {!tickets && <Spinner />}
                {storeInfo && tickets &&
                    <DisplayLandscape 
                    
                    StoreDetails={storeInfo}
                    merchantKey={merchantKey}
                    // Tickets={ _.groupBy(tickets, 'queue_type_id')}
                     StoreInfo={_.sortBy(storeInfo?.queue_options, ['prefix'])}/>
                 }
                
            </>
            )
        case "v2-portrait":
            
            return (
                <>
                <ManagerHeader navActive={false}/>
                {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
                {!tickets && <Spinner />}
                {storeInfo && tickets &&
                    <DisplayPortrait 
                    StoreDetails={storeInfo}
                    merchantKey={merchantKey}
                    // Tickets={ _.groupBy(tickets, 'queue_type_id')} 
                    StoreInfo={_.sortBy(storeInfo?.queue_options, ['prefix'])}/>
                }
                
            </>
            ) 
    
        default:
            return (
                <>
                <ManagerHeader navActive={false}/>
                {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
                {!tickets && <Spinner />}
    
                <div className="display-queue-logo">
                    {storeInfo?.store_logo && <img src={storeInfo?.store_logo} alt={storeInfo?.store_name} style={{height:"65px",objectFit: "cover",}}/>}
                    <h2>{storeInfo?.store_name}</h2>
                </div>
                {storeInfo && tickets &&
                    <TicketColumns merchantKey={merchantKey}  store_meta={storeInfo} StoreInfo={_.sortBy(storeInfo?.queue_options, ['prefix'])}/>
                 }
                 <Footer float={true}/>
            </>
            )
    }
}


export default withRouter (DisplayQueue)
