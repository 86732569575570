import React from 'react';
import "./index.css"

const QueueTicketTimeCall = ({created_at, callingId, store_meta}) => {
    return (
        <div className="queue-ticket-time-call">
            <p>{new Date(created_at).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
            <div className="queue-card-current-call">
                <p>{store_meta?.now_serving_text || "Now Serving"}</p>
                <p style={{fontSize: "1.8rem",fontWeight: "800"}}>{callingId || "―" }</p>
            </div>
        </div>
    )
}

export default QueueTicketTimeCall;
