/* eslint-disable no-undef */
import React, {useState, useEffect}  from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwt_decode from "jwt-decode"
import moment from 'moment'
import Spinner from './components/Spinner'
import { useDocumentData,useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase';
import changeSettings from './helpers/changeSettings'

const ProtectedRoutes = ({component:Component, ...rest}) => {
    const [merchantKey, setMerchantKey] = useState(null);
    const [isAuth, setIsAuth] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        const merchant_key = localStorage.getItem('merchant_key');
        const token = localStorage.getItem('token')

        if(token) {
            const decoded = jwt_decode(token)
            if(decoded?.organization?.merchant_key === merchant_key){
                const checkExp = moment(moment.unix(+decoded?.exp)).isAfter(moment())
                setMerchantKey(decoded?.organization?.merchant_key)
                setIsAuth( checkExp ? true : false)

                
            }else {
                setIsAuth(false)
            }
        }else {
            setIsAuth(false)
        }
        
        setIsLoading(false)
        
       
    }, [])

    const [storeInfo,loading] = useDocumentData(
        firestore.doc(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}/${merchantKey}`),
      );

      changeSettings(storeInfo, "Dashboard")

    // const [tickets] = useCollectionData(
    //     firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).where('status', 'not-in', ['NO_SHOW', 'SEATED', 'EXIT_QUEUE']),
    //     {idField: 'id'}
    //     );
    //     console.log(tickets)
    const tickets = []


    if(isLoading || loading) {
        return <Spinner />
    }
    if(!isAuth) {
        return <Redirect to={{pathname: "/dashboard/login", state:{from: rest.location }}} />
    }
    return <Route  component={(props)=> <Component merchantKey={merchantKey} storeInfo={storeInfo} tickets={tickets} {...props} />}  {...rest}/>
        
}

export default ProtectedRoutes
