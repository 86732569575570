import React from 'react'
import { Link,NavLink } from 'react-router-dom'

const ManagerHeader = ({ navActive }) => {

    const handleToggle = () => {
        const sideNav = document.querySelector(".side-nav")
        const container = document.querySelector(".dash-container")
        sideNav.classList.toggle("hide-nav")
        
        if(container) {
            container.classList.toggle("dash-container-right")
        }

    }

    return (
        <>
            <div className="manage-header">
                <div className="ham" onClick={handleToggle}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                </div>
                <p>TABSQUARE SmartQ</p> 
            </div>
            <div className={ navActive ? "side-nav" : " side-nav hide-nav"}>
                <NavLink className="nav-btn" to="/dashboard/queue-list" activeClassName="nav-btn-active">Queue Display</NavLink>
                <NavLink className="nav-btn" to="/dashboard/queue-list?displayType=v2-landspace" activeClassName="nav-btn-active">Queue Display Landscape</NavLink>
                <NavLink className="nav-btn" to="/dashboard/queue-list?displayType=v2-portrait" activeClassName="nav-btn-active">Queue Display Portrait</NavLink>
                <NavLink className="nav-btn" to="/dashboard/manage-queue" activeClassName="nav-btn-active" >Manage Queue</NavLink>
                <NavLink className="nav-btn" to="/dashboard/queue-settings" activeClassName="nav-btn-active" >Queue Settings</NavLink>
                <NavLink className="nav-btn" to="/dashboard/report" activeClassName="nav-btn-active" >Report</NavLink>
                <Link className="nav-btn" onClick={() => localStorage.clear()} to='/dashboard/login' >Logout</Link>
            </div>
        </>
    )
}

ManagerHeader.defaultProps = {
    navActive : true
}

export default ManagerHeader
