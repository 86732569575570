import React from 'react';
import _ from "lodash"
import "./index.css"
import HorizontalLine from '../HorizontalLine';

const QueueCard = ({queObj,tickets, store_meta}) => {

    const lastestCalling = _.findLast(tickets, {'status': "CALLING"})

    const getNext = tickets?.length || 0

    const nextTicketNo = queObj.prefix + (+getNext + 1).toString().padStart(3, '0')

    return (
        <div className="queue-wrapper">
            <div className="queue-card">
                <div className="now-calling">
                    <p>{store_meta?.now_serving_text || "Now Serving"}</p>
                    <p>{lastestCalling?.id || "―"}</p>
                </div>
                <HorizontalLine changeColor={true} />
                <div className="next-available">
                    <p>Next Available</p>
                    <p>{nextTicketNo || "―"}</p>
                </div>
                <HorizontalLine changeColor={true} />
                <div className="no-of-guests">
                    <p>{queObj.name}</p>
                </div>
            </div>
        </div>
    )
}

export default QueueCard;
