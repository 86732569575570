import React from 'react'
import "./index.css"

const InputBox = ({labelName, placeholder,value, setValue, required, err,isNumber, children}) => {
    return (
        <>
        <div className="input-wrapper" style={{marginBottom: "1.6rem"}}>
        <div className="input-box" style={{margin:0, border: err? "2px solid #ff3535" : "2px solid var(--color-border)"}}>
            <label>
                {labelName} 
                <span>*</span>
            </label>
            {
                children ? children : (
                    isNumber ? 
                    <input type={"number"} 
                        min="1" max="99"
                        required={required} value={value} onChange={(e) => setValue(e.target.value)} className="name-input" placeholder={placeholder} />
                    :   
                    <input type="text"
                        required={required} value={value} style={{fontSize: "1rem", paddingBottom:"0.7rem"}} onChange={(e) => setValue(e.target.value)} className="name-input" placeholder={placeholder} />
                )
            }
        </div>
            {/* {err &&  <p className="error-message"><small>{labelName} is Required</small></p>} */}
        </div>
        </>
    )
}

export default InputBox;
