import React, {useState, useEffect}  from 'react'
// import { Redirect, Route } from 'react-router-dom'
// import jwt_decode from "jwt-decode"
// import moment from 'moment'
import notificationSound from "../../../../assets/sounds/notification.mp3";
import _ from 'lodash';
// import Spinner from './components/Spinner    '
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../../firebase';

const TicketLandscape = ({ StoreDetails, StoreInfo, merchantKey }) => {
    const date = new Date().toISOString().replace(/T.*/,'').split('-').reverse().join('-')

    const [ticketsQ] = useCollectionData(
    firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).where('status', 'not-in', ['NO_SHOW', 'SEATED', 'EXIT_QUEUE']),
    {idField: 'id'}
    );


    const Tickets = _.groupBy(ticketsQ, 'queue_type_id')



    var notification = new Audio(notificationSound);

    // const search = useLocation().search;
    // const videoFitScreen = new URLSearchParams(search).get("videoFitScreen");
  
    // TODO Cleanup
    const getCalling = localStorage.getItem("current_calling");
    const oldCalling = getCalling?.split(",").filter(Boolean);
  
    let calling = [];
    StoreInfo.map((store) =>
      calling.push(
        Tickets[store.prefix]
          ?.filter((store) => store?.status === "CALLING")
          .map((activeT) => activeT.id)
      )
    );
  
    calling = calling.flat().filter(Boolean);
  
    if (calling?.length > oldCalling?.length) {
      const promise = notification.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            // console.log("New Ticket")
          })
          .catch((error) => {
            notification.muted = true;
            notification.play();
          });
      }
    }
  
    localStorage.setItem("current_calling", calling);
  
  
  
    // const slideUpFrame = keyframes`${slideInUp}`;
  
    // const SlideUpDiv = styled.div`
    //   animation: 0.5s ${slideUpFrame};
    // `;
  
    // const pulseFrame = keyframes`${pulse}`;
    // const PulseH1 = styled.h1`
    //   animation: 1.5s ${pulseFrame};
    // `;
  
    const queueCellLimit = StoreInfo?.length > 3 ? 2 : 3



  return (
    <div
        style={{
          width: "40%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor:"white"
        }}
      >
        <table className="lndscp-table" style={{height:"100%", border:"0"}}>
          
          <thead>
            <tr>
              <th colspan="3" className=''>
                <div className="display-queue-logo" style={{padding:"0"}}>
                      {StoreDetails?.store_logo && <img src={StoreDetails?.store_logo} alt={StoreDetails?.store_name} style={{height:"65px",objectFit: "cover",}}/>}
                      <h2>{StoreDetails?.store_name}</h2>
                  </div>
              </th>
              
            </tr>
            <tr>
              <th>PAX</th>
              <th className="calling">{StoreDetails?.now_serving_text || "NOW SERVING"}</th>
              <th className="queue">NEXT IN QUEUE</th>
            </tr>
          </thead>

          <tbody className="table-landscape-body">
            {StoreInfo &&
              StoreInfo.map((storeTicket, index) => (
                <tr>
                  <td height="0"  className="white-text" >{storeTicket.name}</td>
                  <td  height="0" style={{paddingLeft:"0"}}>
                      {Tickets[storeTicket.prefix]?.filter(
                        (store) => store?.status === "CALLING"
                      )?.length > 0 ? (
                        Tickets[storeTicket.prefix]
                          ?.filter((store) => store?.status === "CALLING")
                          .map((activeT) => (
                            <div  key={activeT?.id} className="green-text" style={{fontSize: "xxx-large"}}>{activeT?.id}</div>
                          ))
                      ) : (
                        <div className="green-text" style={{fontSize: "xxx-large"}}>―</div>
                      )}
                  </td>

                  <td valign="top" className="td-height" style={{textAlign: "left",}} >
                    <div >

                      {Tickets[storeTicket.prefix] &&  Tickets[storeTicket.prefix]?.filter(_ => _?.status === 'IN_QUEUE')
                          .slice(0, queueCellLimit)
                          .map((tick,i) => 
                            <div  key={tick.id} style={{fontSize: "xx-large"}}>{tick.id}</div>
                      )}
                
                      {Tickets[storeTicket.prefix]?.filter(_ => _?.status === 'IN_QUEUE')?.length > queueCellLimit &&
                          <div  style={{fontSize: "xx-large"}}>
                            +{Tickets[storeTicket.prefix]?.filter(_ => _?.status === 'IN_QUEUE')?.length - queueCellLimit} 
                            <span style={{display:"inline", fontSize:"x-large"}}> others</span></div>
                      }
                    </div>

                    {/* <table className="lndscp-table td-table"  style={{height:"100%", border:"0"}}>
                        {Tickets[storeTicket.prefix] &&  Tickets[storeTicket.prefix]?.filter(_ => _?.status === 'IN_QUEUE')
                        ?.slice(0, 2)
                        .map((tick,i) => 
                          <tr key={tick.id}>
                              <td className="border-none p-0" style={{fontSize: "xx-large"}}>{tick.id}
                              <span className="badge-ticket" >+{Tickets[storeTicket.prefix]?.filter(_ => _?.status === 'IN_QUEUE')?.length - 1}</span></td>
                          </tr>
                        )}
                    </table> */}
                  
                  </td>

                </tr>
              ))}
          </tbody>
        </table>
        
      </div>
  )
}

export default TicketLandscape