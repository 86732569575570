import React from 'react';
import "./index.css";

const HorizontalLine = ({changeColor}) => {
    return (
        <div className="horizontal-line" style={changeColor && {borderBottomColor: "#000000"}}></div>
    )
}

export default HorizontalLine
