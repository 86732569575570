/* eslint-disable no-undef */
import React, {useState, useEffect}  from 'react'
import { Redirect, Route } from 'react-router-dom'
import Spinner from './components/Spinner'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase';
import changeSettings from './helpers/changeSettings';

const UserRoutes = ({component:Component, ...rest}) => {

    const slug = rest.computedMatch.params?.slug

    const [isViewTicket, setIsViewTicket] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [storeSnap, loading] = useCollectionData(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`).where("store_slug", '==', slug), { idField: "id" },
    );
    const storeInfo = storeSnap && storeSnap[0]

    const stateProps = []    

    useEffect(() => {
        if (!loading && storeSnap?.length <= 0) {
            setIsValid(false)
        }else {

            changeSettings(storeInfo)

            const ticketInfo = JSON.parse(localStorage.getItem("ticket_info"))
            if(ticketInfo && ticketInfo?.status === 'IN_QUEUE') {
                setIsViewTicket(ticketInfo && ticketInfo?.sharing_id ? true : false)
            }
        
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeSnap])


    if(loading) {
        return <Spinner />
    }
    if(isViewTicket) {
        return <Redirect  to={{pathname: `/${slug}/view-ticket`, state:{from: rest.location }}} />
    }
    if(!isValid) {
        return <Redirect to={{pathname: "/404", state:{from: rest.location }}} />
    }
    return <Route  component={(props)=> <Component  storeInfo={storeInfo} {...stateProps} {...props} />}  {...rest}/>
        
}

export default UserRoutes
