import _ from 'lodash';
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import Footer from '../../../components/Footer';
import ManagerHeader from '../../../components/ManageHeaders'
import Spinner from '../../../components/Spinner';
import { firestore } from '../../../firebase';
import FormPopup from './components/FormPopup';
import StoreForm from './components/storeForm'
import TableView from './components/TableView';


const QueueSettings = ({merchantKey,storeInfo}) => {

    

    const date = new Date().toISOString().replace(/T.*/,'').split('-').reverse().join('-')
    const formFields = {
        is_active: false,
        max_pax: "",
        min_pax: "",
        name: "",
        prefix: "",
        id: ""
    }

    const [mode ,setMode] = useState("")
    const [option, setOption] = useState(formFields)
    const [openModal, setOpenModal] = useState(false)
    const [formError, setFormError] = useState("")
    const [qEditable, setQEditable] = useState(false)


    const closeModal = () => {
        setOpenModal(false); 
        setOption(formFields);
        setFormError("")
    }    

    const handlePopup = async(mode, index) => {
        if (mode === "EDIT"){
            setMode(mode)
            const queue = storeInfo.queue_options[index]
            if(queue) {

                const ticketList = await firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).where('queue_id', '==', queue?.id).where('status', 'in', ['IN_QUEUE', 'CALLING']).get()

                setQEditable(ticketList?.empty ? true : false)

                setOption(queue)
                setOpenModal(true)
            }
        }else  {
            setMode(mode)
            setOpenModal(true)
        }
        

    }  

    const handleOptionSubmit = async(e,id) => {
        e.preventDefault();
        const queue = storeInfo.queue_options ?  storeInfo.queue_options : []
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`).doc(merchantKey);
        
        try {
            
            for (const q of queue) {
               if(mode === "EDIT" && q.id === option?.id) {
                   continue;
               } 
                switch (true) {
                    case +option.min_pax >= +option.max_pax:
                        throw new Error(`Min Pax should be lesser than Max Pax `)

                    case +q.min_pax >= +option.min_pax || +option.min_pax <= +q.max_pax:
                        throw new Error(`${option.min_pax} Min Pax already Exist's in a range`)
                    
                    case +q.min_pax >= +option.max_pax || +option.max_pax <= +q.max_pax:
                        throw new Error(`${option.max_pax} Max Pax already Exist's in a range`)
                    
                    case q.prefix.toUpperCase() === option.prefix.toUpperCase():
                        throw new Error(`Prefix ${option.prefix} already Exist's`)

                    default:
                       
                }
            }

        if (mode === "EDIT" && id){
            const queueIndex = _.findIndex(queue, {id})
            queue[queueIndex] = option
            await docRef.set({
                queue_options : queue
                
            }, {merge:true});
        

           closeModal()
        }else if (mode === "ADD") {
            const currentQ = queue.length >= 0 ? queue : {}
            const data = [ ...currentQ , {...option, id: +queue.length + 1, prefix:option.prefix.toUpperCase()}]
            await docRef.set({
                queue_options : data            
            }, {merge:true});


            closeModal()
        }



        } catch (error) {
            setFormError(error?.message || "Something Went Wrong, Please re-load page")
            return;
        }

        
        
    }

    const handleOptionDelete = async(id) => {
        const queue = storeInfo.queue_options ?  storeInfo.queue_options : []
        if(queue.length >= 0){
            const newQueueList = queue.filter(q => q.id !== +id)

            const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`).doc(merchantKey);
 
            await docRef.set({
               queue_options : newQueueList
                
            }, {merge:true});
            closeModal()
        }
    }

    return (
        <>
            <ManagerHeader navActive={true}/>
            {!storeInfo ? <Spinner /> : (
                <div className="dash-container dash-container-right" style={{padding:"10px 40px"}}>
                    <StoreForm storeInfo={storeInfo} merchantKey={merchantKey}/>


                    <TableView storeInfo={storeInfo} handlePopup={handlePopup} merchantKey={merchantKey}/>
                    <FormPopup mode={mode} qEditable={qEditable} formError={formError} setFormError={setFormError} option={option} setOption={setOption} 
                    handleOptionDelete={handleOptionDelete}
                    handleOptionSubmit={handleOptionSubmit} 
                    openModal={openModal} closeModal={closeModal}/>
                </div>
            )}
           <Footer float={true}/>
        </>

  
    )
}

export default withRouter (QueueSettings)
