import React from 'react';
import "./index.css";

const Welcome = ({welcomeTxt}) => {
    return (
        <div className="welcome">
            <p className="welcome-message">{welcomeTxt || "Welcome! Please enter the details below to get a Queue Number, we will inform you by SMS as soon as Table is available."}</p>
        </div>
    )
}

export default Welcome
