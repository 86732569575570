import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
const DuplicatePopup = ({ticket, handleExitQ, handleContinueQ}) => {
    const contentStyle = { width: '90% !important' };
    return (
        <Popup open={true} modal className="duplicate-pop" closeOnDocumentClick={false} {...{  contentStyle }} position='center center' >
        <div className="modal">
            <div style={{padding: "15px",backgroundColor:"#ffcd04c2" }} >
                <h2 style={{textAlign:"center"}}>Duplicate Ticket Found !</h2></div>
            <div className="modal-container" style={{paddingBottom: "20px", paddingTop:"5px"}}>
                <div class="queue-card-rectangle">
                    <div class="queue-card-semicircle"></div>
                    <div className="queue-card-dotted-line"></div>
                    <div className="queue-card-content">
                        <p style={{color: "#ED2E7E"}}>{ticket?.tick_no}</p>
                        <p style={{color: "#ED2E7E"}}> <small>{ticket?.queue_id} Guest<small>(s)</small></small> </p>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 10px"}}>
                      <button type="button" className="btn btn-primary" onClick={handleExitQ} style={{width: "10px 25px", padding:"10px 15px"}}   ><b>Exit Old Ticket</b></button>
                      <button type="button"  className="btn btn-primary" onClick={handleContinueQ}  style={{backgroundColor:"#008000d6",width: "10px 25px", padding:"10px 15px"}}  ><b>Continue with {ticket?.tick_no} </b></button> 
                      
                    </div>
            </div>
        </div>
      </Popup>
    )
}

export default DuplicatePopup
