import { datadogLogs } from "@datadog/browser-logs";

const info = (data) => {
  try {
    datadogLogs.logger.info(data);
  } catch (e) {
    console.log(e);
  }
};
const error = (data) => {
  try {
    datadogLogs.logger.error(data);
  } catch (e) {
    console.log(e);
  }
};

const writeInfoLog = (message, data) => {
  if (data) {
    info(Object.assign(data, { message }));
  } else {
    info(message);
  }
};

const writeErrorLog = (message, data = null) => {
  if (data) {
    error(Object.assign(data, { message }));
  } else {
    info(message);
  }
};

export { writeInfoLog, writeErrorLog };
