import React, { Component } from "react";
import Slider from "react-slick";

export default class AutoPlayMethods extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      className: "slick-q",
      afterChange: () => (document.querySelector('.slick-active div div video')?.play())
    };
    return (
      <div style={{height:"100%", display: "flex", flexDirection: "column", justifyContent:"center"}}>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
           {this.props.data.map((ele, i) => 
            
            ele.type === 'image' ? 
            <div key={i} >
                {this.play}
                <img src={ele.src} alt={ele.src}/>
            </div> 
            :
            <div key={i}>
              <video 
                autoPlay={true}
                playsinline
                onPlay={this.pause}
                controls={true}
                onPla
                onEnded={this.play}
                muted
                width="100%" 
                src={ele.src}>
                  
              </video>
            </div>            
            )} 
        </Slider>
      </div>
    );
  }
}