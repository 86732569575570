import React from 'react';
import "./index.css";

const Header = () => {
    return (
        <header className="header-container">
            <div className="header">SmartQ</div>
        </header>
    )
}

export default Header;
