import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
const FormPopup = ({mode, option, openModal,closeModal,setOption,handleOptionSubmit,formError,setFormError, handleOptionDelete,qEditable}) => {
    return (
        <Popup open={openModal} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <button className="close" onClick={closeModal}>
            &times;
            </button>
            <div style={{padding: "15px",backgroundColor:"var(--color_primary)", color:"var(--color_font)" }} ><h1 style={{textAlign:"center"}}>Add Queue Option</h1></div>
            <div className="modal-container" style={{padding: "20px", paddingTop:"5px"}}>
              <form onSubmit={(e) => handleOptionSubmit(e, option.id)}>
                <div className="input-boxes">
                    <div className="input-box">
                        <label htmlFor="store_name">Option NAME</label>
                        <input readOnly={mode === 'EDIT' ? true : false} type="text" placeholder="Enter Option Name" className="name-input" value={option.name} onChange={(e) => setOption({...option, name: e.target.value}) }/>
                    </div>
                     <div  className="input-box">
                        <label htmlFor="store_name">Option Prefix</label>
                        <input readOnly={mode === 'EDIT' ? true : false} type="text" placeholder="Enter Option Prefix"  className="name-input"  value={option.prefix} onChange={(e) => {setOption({...option, prefix: e.target.value}); setFormError("")} } required/>
                      </div> 
                    
                   

                    <div  className="input-box">
                        <label htmlFor="store_name">Min Pax</label>
                        <input type="number" min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" placeholder="Enter Minimum Person"  className="name-input" value={option.min_pax} onChange={(e) => setOption({...option, min_pax: e.target.value}) }/>
                      </div> 

                      <div  className="input-box">
                        <label htmlFor="store_name">Max Pax</label>
                        <input type="number" min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"  placeholder="Enter Maximum Person" className="name-input" value={option.max_pax} onChange={(e) => setOption({...option, max_pax: e.target.value}) }/>
                      </div> 


                      {/* <div className="check-box">
                        <div className="round">
                            <input type="checkbox-active" id="store status" checked={option.is_active}  onChange={(e) => setOption({...option, is_active:e.target.checked})} />
                            <label htmlFor="checkbox-active"></label>
                        </div>
                        <div className="chechbox-name">Option Active</div>
                    </div> */}

                    
                    {formError && <div className="error-wrapper"><p>{formError}</p></div>}
                    {mode === "EDIT"  && !qEditable && <div className="error-wrapper" >
                      <p>Queue<small>(s)</small> Are Still Active !! </p>
                    </div> }
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 10px"}}>
                      <button type="submit" disabled={mode === "EDIT"  &&  !qEditable ? true : false} className="btn btn-primary"  ><b>SAVE</b></button>
                      {mode === "EDIT"  && <button type="button" disabled={qEditable ? false : true} onClick={() => handleOptionDelete(option.id)} className="btn btn-primary"  ><b>DELETE</b></button> }
                      
                    </div>
                </div>
              </form>
            </div>
        </div>
      </Popup>
    )
}


export default FormPopup
