// import _ from 'lodash';
import React from 'react'
import notificationSound from '../../../../assets/sounds/notification.mp3'
import styled, { keyframes } from 'styled-components';
import { pulse, slideInUp } from 'react-animations';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../../firebase';
import _ from 'lodash';


const style = {
    ticketGrids: {
        display: "flex", flexDirection: "row", flexFlow: "wrap", justifyContent: "center"
    },
    ticketCol: { margin: "30px", fontFamily: "'Nunito Sans', sans-serif", fontWeight: 800 },
    ticketInQueue: { border: "1px solid grey", textAlign: "center" }
}

const TicketColumns = ({ StoreInfo, merchantKey, store_meta }) => {

    const date = new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-')

    const [ticketsQ] = useCollectionData(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${merchantKey}/${date}`).where('status', 'not-in', ['NO_SHOW', 'SEATED', 'EXIT_QUEUE']),
        { idField: 'id' }
    );


    const Tickets = _.groupBy(ticketsQ, 'queue_type_id')

    var notification = new Audio(notificationSound);


    // TODO Cleanup
    const getCalling = localStorage.getItem('current_calling')
    // const oldCalling = getCalling?.split(',').filter(Boolean)
    const oldCalling = getCalling?.split(',')

    


    let calling = [];
    StoreInfo.map(store =>
        calling.push(Tickets[store.prefix]?.filter(store => store?.status === 'CALLING').map(activeT => activeT.id))
    )

    let activeTickets = []

    Object.keys(Tickets).forEach(function(key) {
        activeTickets.push( ...Tickets[key].filter(e => e.status === "IN_QUEUE").map(e => e.id))
    });

    const getOldTickets = JSON.parse(localStorage.getItem('old_tickets')) || []


   




    // calling = calling.flat().filter(Boolean)
  
    if (getOldTickets && getOldTickets?.length > 0 &&  _.difference(activeTickets, getOldTickets, )?.length > 0) {

        const promise = notification.play();


        if (promise !== undefined) {
            promise.then(() => {
                // console.log("New Ticket")
            }).catch(error => {
                console.log(error)
                notification.muted = true;
                notification.play();
            });
        }
    }


    localStorage.setItem('current_calling', calling.flat().filter(Boolean))

   

    localStorage.setItem('old_tickets', JSON.stringify([...activeTickets]))


    const slideUpFrame = keyframes`${slideInUp}`;

    const SlideUpDiv = styled.div`
      animation: 0.5s ${slideUpFrame};
    `;

    const pulseFrame = keyframes`${pulse}`;
    const PulseH1 = styled.h1`
      animation: 1.5s ${pulseFrame};
    `;
    return (

        <div className="dash-container" style={style.ticketGrids}>
            {/* <audio id="notification" src="../../../../assets/sounds/notification.mp3" autoPlay></audio> */}

            {StoreInfo && StoreInfo.map((store, index) => (
                <div key={store?.id} style={style.ticketCol} className="ticket-col-container">
                    <div className="ticket-calling" style={{ padding: "0", color: "rgb(22 116 26)", border: "2px solid #2E7D32" }} >
                        <div style={{ backgroundColor: "lightgreen", borderTopRightRadius: "13px", borderTopLeftRadius: "13px", paddingTop: "10px" }}><h2 style={{ margin: "0", paddingBottom: "10px", fontSize: "2rem" }}>{store_meta?.now_serving_text || "Now Serving"}</h2></div>
                        <div style={{ padding: "10px 91px" }}>
                            <p>{store.name}  </p>
                            {Tickets[store.prefix]?.filter(store => store?.status === 'CALLING')?.length > 0 ? Tickets[store.prefix]?.filter(store => store?.status === 'CALLING').map(activeT => <PulseH1 key={activeT?.id}>{activeT?.id}</PulseH1>) : <h1>―</h1>}


                        </div>


                    </div>


                    {Tickets[store.prefix] && Tickets[store.prefix]?.filter(_ => _?.status === 'IN_QUEUE').map((tick, i) => {
                        return (
                            <>
                                {i === 0 &&
                                    <div className="ticket-in-queue" style={{ backgroundColor: "#FBC02D", border: "1px solid #F9A825" }}>
                                        <h2 style={{ margin: "0", padding: "10px", fontSize: "1.7rem", color: "black" }}>Next In Queue </h2>
                                    </div>
                                }
                                <SlideUpDiv key={tick.id} className="ticket-in-queue">
                                    <h1>{tick.id}</h1>
                                </SlideUpDiv>
                            </>
                        )
                    })}
                </div>
            ))}

        </div>
    )
}

export default TicketColumns
