import React from 'react';
import "./index.css"

const QueueAvailable = () => {
    return (
        <div className="queue-available-message">
          <h3>Hi There!</h3>
          <p>The store is currently not issuing new Queue numbers, Please contact staff for any assistance.</p> 
          <p>Thank you.</p> 
        </div>
    )
}

export default QueueAvailable
