import React,{useState,useRef} from "react";
import TicketLandscape from "./TicketLandscape";
import AutoPlayMethods from "./carousel";
const TicketColumnsNewLandscope = ({
  StoreDetails,
  StoreInfo,
  merchantKey,
}) => {
  // const data = [
  //   {type: "video", src: "https://storage.googleapis.com/loyalty_app_images/smartq/kfc-promo.mp4"},
  //   {type: "image", src: "https://via.placeholder.com/800x800"},
  //   {type: "video", src: "https://storage.googleapis.com/loyalty_app_images/smartq/promo3.mp4"},
  //   {type: "image", src: "https://via.placeholder.com/800x800"},
  // ]
  const ref = useRef(null)
  const [isShown, setIsShown] = useState(false);
  return (
    <div
      style={{ display: "flex", height: "92.4vh", backgroundColor: "black" }}
    >
            {/* <p className="resolution-info">asdas</p> */}
      <div className="promotion-side"
        ref={ref}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
       >

        {isShown && <p className="resolution-info">{ref.current.clientWidth}x{ref.current.clientHeight}</p>}
        <AutoPlayMethods data={StoreDetails?.promotions || []} />
      </div>

      <TicketLandscape
        StoreDetails={StoreDetails}
        StoreInfo={StoreInfo}
        merchantKey={merchantKey}
      />
    </div>
  );
};

export default TicketColumnsNewLandscope;
