import axios from 'axios'
import React, { useState } from 'react'
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";
import { withRouter } from 'react-router'
import Footer from '../../../components/Footer';
import logo from '../../../assets/images/logo.png'
import logoWhite from '../../../assets/images/logo-white.jpg'
const Login = ({history}) => {

    const favi = document.getElementById("favicon");
    favi.href = logoWhite

    const [creds, setCreds] = useState({email:"", password:""})
    const [err, setErr] = useState(null)
    const [loadingText, setLoadingText] = useState("")

    const [loading, setLoading] = useState(false)

    const handleLogin = async(e) => {
        e.preventDefault()

        try {
            setErr(null)
            setLoading(true)
            const data = await axios.post(`${process.env.REACT_APP_MERCHANT_LOGIN_API_URL}`,
            { "strategy": "local",
            "email": creds.email,
            "password": creds.password})

            const token = data?.data?.accessToken
            if(!token ){
                setErr( "Something went wrong, Try again!")
                setLoading(false)
                return
            }

            const decoded = jwt_decode(token)
            const merchantKey = decoded?.organization?.merchant_key    
            if (merchantKey){
                localStorage.clear()
                localStorage.setItem("merchant_key", merchantKey)
                localStorage.setItem("token", token)
                localStorage.setItem("exp", decoded?.exp)
                
                
            }else {
                setErr( "Something went wrong, Try again!")
                setLoading(false)
                return
            }
            

            const {data:result} = await axios.get(`${process.env.REACT_APP_VALIDATE_STORE_API_URL}/${decoded?.organization?.merchant_key}`)

            if(result?.data?.is_valid && result?.data?.is_active) {
                history.push('/dashboard/manage-queue')
                return
            }else if(!result?.data?.is_active) {
                throw new Error("Store is not enabled from ecms !")
            }
            else if(!result?.data?.is_valid && result?.data?.is_active) {
                setLoadingText("We are Setting-up your store, Please Wait !!")
                await axios.post(`${process.env.REACT_APP_SETUP_STORE_API_URL}/${decoded?.organization?.merchant_key}`)
                history.push('/dashboard/manage-queue')
                return
            }

            setLoading(false)

        } catch (error) {
            console.log(error?.response?.data)
            setErr(error?.response?.data.message || error?.response?.data?.errors?.message ||  error?.message || "Something went wrong, Try again!")
            setLoadingText("")
            setLoading(false)
        }

    }

    return (
        <div className="container-wrapper login-background" style={{border: "0",boxShadow:"0"}}>
                <div className="login-box" style={{border: "0",boxShadow:"0"}}>
                
                    <div className="" style={{backgroundColor:"#f2f2f2", display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"1rem"}}>
                        <img src={logo} width="125" alt="" />
                    </div>
                    <div className="form-container" style={{backgroundColor:"#f2f2f2"}}>
                        <form onSubmit={handleLogin}>
                           <div  className="input-boxes" style={{backgroundColor:"#f2f2f2"}}>
                            <div className="input-box" style={{padding:0}}>
                                <label htmlFor="email" style={{color: "#532d6d", backgroundColor:"#f2f2f2"}}>Email</label>
                                <input type="email" placeholder="Enter your Email" value={creds.email} id="email" onChange={(e) => setCreds({...creds, email: e.target.value})} style={{paddingLeft:"1.2rem",backgroundColor:"#f2f2f2"}} className="name-input" required/>
                            </div>
                            <div className="input-box" style={{padding:0, marginBottom:0}}>
                                <label htmlFor="password" style={{color: "#532d6d",backgroundColor:"#f2f2f2"}}>Password</label>
                                <input type="password" placeholder="Enter your Password" value={creds.password} onChange={(e) => {setCreds({...creds, password: e.target.value}); setErr(null)}} id="password" style={{paddingLeft:"1.2rem",backgroundColor:"#f2f2f2"}} className="name-input" required/>
                                
                            </div>

                            <button style={{marginTop: "1.6rem", backgroundColor: "#532d6d"}} className="btn btn-primary"><b>
                                {loading ? 
                                <Loader  
                                    type="ThreeDots"
                                    color="white"
                                    height={"12px"}
                                    width={"45px"}
                                />  
                                
                                : "Submit"} </b></button> 
                                {err && <div><p className="error-message" style={{paddingTop:"10px"}}>{err}</p></div>}
                                {loadingText && <div><p className="error-message" style={{paddingTop:"10px", color: "green", fontWeight: "600" }}>{loadingText}</p></div>}   
                           </div>
                        </form>
                    </div>
                </div>
        {/* <Footer float={false}/> */}
        </div>
    )
}

export default withRouter (Login)
