import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import Header from '../Header'
import Welcome from './Welcome'
import Location from './Location'
import HorizontalLine from './HorizontalLine'
import QueueCard from './QueueCard'
import InputBox from './InputBox'
import CheckBox from '../../../components/CheckBox'
import _ from "lodash"
import "./container.css"
import QueueAvailable from './QueueAvailable'
import CountryDropdown from './CountryDropdown'
import { firestore, firebase } from '../../../firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import axios from 'axios';
import moment from 'moment';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { nanoid } from 'nanoid'
import DuplicatePopup from './components/DuplicatePopup'
import Loader from "react-loader-spinner";
import Select from 'react-select';
import Footer from '../../../components/Footer'
import { writeInfoLog } from '../../../helpers/logger'
 

const QueueHome = ({ history,storeInfo }) => {

    const [loading, setLoading] = useState(false)
    const [storeOpen, setStoreOpen] = useState(false)
    const [isQueueAvailable, setIsQueueAvailable] = useState(storeInfo?.is_active && true)


    
    const MINUTE_MS = 5000;
    const today = moment().format('dddd').toLowerCase()

    useEffect(() => {
    const interval = setInterval(() => {
        if(storeInfo?.opening_hours) {
        
            if(storeInfo?.opening_hours[today] && storeInfo?.opening_hours[today]?.length > 0){
                
                for (let ele of storeInfo?.opening_hours[today]) {
                    const [start, end] = ele?.split("-")
    
                    if(moment(moment().format("HH:mm"), "HH:mm").isBetween(moment(start, "HH:mm"), moment(end, "HH:mm"), 'minutes', '[]')){
                       
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        // setStoreOpen(true)
                        setIsQueueAvailable(storeInfo?.is_active && true)
                        break;
                    }else{
                        setIsQueueAvailable(false)
                    }
    
                  }
    
            }
    
        }else {
            const timeState = moment(moment().format("HH:mm"), "HH:mm").isBetween(moment(storeInfo?.store_start_time, "HH:mm"), moment(storeInfo?.store_end_time, "HH:mm"), 'minutes', '[]')
            setIsQueueAvailable(storeInfo?.is_active && timeState)
        }
    
    }, MINUTE_MS);

    return () => clearInterval(interval); 
    }, [])


    // useEffect(() => {
    //     storeOpen
    
    //   return () => {
    //     second
    //   }
    // }, [storeOpen])
    


    const sms_alerts = _.isBoolean(storeInfo?.enable_sms_alerts) ?  storeInfo?.enable_sms_alerts: true

    const formInputs = {
        queue_id: "",
        customer_id: -1,
        customer_name: "",
        customer_phone: "",
        send_sms: sms_alerts ? storeInfo?.enable_sms_by_default || false : false,
        status: 'IN_QUEUE'
    }

    

    const date = new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-')
 

    const [formData, setFormData] = useState(formInputs)
    const [checkErr, setCheckErr] = useState(false)
    const [duplicateTicket, setDuplicateTicket] = useState(null)


    const urlPrefix = storeInfo?.store_slug
    const queueOptions = storeInfo?.queue_options.filter(q => q.is_active)

    const [tickets] = useCollectionData(
        firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id || "-"}/${date}`),
        { idField: 'id' }
    );


    const groupedTickets = _.groupBy(tickets, 'queue_type_id')
 

    






    const handleNewTicket = async (e) => {

        e?.preventDefault();
        setLoading(true)
        if (!formData.customer_name || !formData.queue_id || !formData.customer_phone) {
            setCheckErr(true)
            setLoading(false)
            return
        }
        const QueueInfo = _.find(storeInfo.queue_options, (q) => +formData.queue_id >= +q.min_pax && +formData.queue_id <= +q.max_pax  )

       if(e){
        
        if(!isValidPhoneNumber("+" + formData.customer_phone)){
            toast.error(`Invalid Phone number`)
            setFormData(prev => ({...prev, customer_phone: ""}) )
            setCheckErr(true)
            setLoading(false)
            return
        }

        

        if(!QueueInfo){
            toast.error(`Sorry, No Slots Avaliable For ${formData.queue_id} Guest(s)`)
            setFormData(formInputs)
            setLoading(false)
            return
        }

        if(!QueueInfo?.is_active){
            toast.error(`Currently No Vacancies For ${formData.queue_id} Guest(s), Please Try Again Later!`)
            setFormData(formInputs)
            setLoading(false)
            return
        }

        const query = await firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id|| "-"}/${date}/`)
            .where("customer_phone", "==", `+${formData?.customer_phone}`).where("status", "in", ["IN_QUEUE", "CALLING"]).get()

        if(!query.empty) {
            setLoading(false)
            setDuplicateTicket({...query.docs[0].data(), tick_no:query.docs[0].id})
            return
        }

        
     }

     let custId;
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_CUSTOMER_LOGIN_API_URL}/${storeInfo?.id}`,
                { "customer_name": formData.customer_name, "customer_phone_number": "+" + formData.customer_phone })
            
                if(!data?.data?.customer_id) {
                throw new Error()
            }
            
            setFormData({...formData, customer_id: data?.data?.customer_id})
            custId=data?.data?.customer_id
            
        } catch (error) {
            console.log(error, "Kiosk API ERROR")
            toast.error(`Something Went Wrong, Try Again`)
            setCheckErr(true)
            setLoading(false)
            return
        }

        const getNext = groupedTickets[QueueInfo.prefix]?.length || 0

        const nextTicketNo = QueueInfo.prefix + (+getNext + 1).toString().padStart(3, '0')

        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id}/${date}`).doc(nextTicketNo);

        const userActionTime = new Date();
        const timeAction = userActionTime.getTime();
        const ticketData = {
            ...formData,
            queue_id : +QueueInfo.id,
            no_guests: +formData.queue_id,
            customer_phone: "+" + formData?.customer_phone,
            customer_id: custId || formData?.customer_id,
            queue_type_id: QueueInfo.prefix,
            queue_type: QueueInfo.name,
            created_at: timeAction,
            sharing_id: nanoid(10),
            ticketNo: nextTicketNo
        }


        writeInfoLog(`newTicket | merchantKey: ${storeInfo?.id} | ticketNo: ${nextTicketNo} | actionDate: ${userActionTime} | actionTime: ${userActionTime}`, {ticketData, docRef});

        try {
          ticketData.server_created_at = firebase.firestore.FieldValue.serverTimestamp();
        } catch (error) {
          console.log('error while server created at', error);
        }

        await docRef.set({
            ...ticketData
        }, { merge: true });

        localStorage.setItem("ticket_info", JSON.stringify({ tick_no: nextTicketNo, ...ticketData }))
        localStorage.setItem("ticket_no", nextTicketNo)
        localStorage.setItem("ticket_date", date)
        setFormData(formInputs)
        setLoading(false)
        history.push(`/${urlPrefix}/view-ticket`)
        return;




    }


    const handleExitQ = async () => {
        setLoading(true)
        const oldTicket = duplicateTicket;
        setDuplicateTicket(null)
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_TICKET_PATH}/${storeInfo?.id}/${date}`).doc(oldTicket.tick_no);
        await docRef.update({
            "status": "EXIT_QUEUE"
        }, { merge: true });
        await handleNewTicket()
        return
    }

    const handleContinueQ = () => {
        setLoading(true)
        const oldTicket = duplicateTicket
        setDuplicateTicket(null);
        localStorage.setItem("ticket_info", JSON.stringify({tick_no: oldTicket.tick_no, ...oldTicket}))
        localStorage.setItem("ticket_no", oldTicket.tick_no)
        localStorage.setItem("ticket_date", date)
        setFormData(formInputs)
        setLoading(false)
    
        history.push(`/${urlPrefix}/view-ticket`)
        return;
    }

    const options = []

    storeInfo?.queue_options.forEach(ele => {
        const range = _.range(+ele.min_pax, +ele.max_pax +1)
       
        const group = range.map(pax => { return {value: pax, label: ele.is_active ? pax + ` Guest${pax > 1 ? "s": ""}` : pax + ` Guest${pax > 1 ? "s": ""} (𝘂𝗻𝗮𝘃𝗮𝗶𝗹𝗮𝗯𝗹𝗲)`, isDisabled: ele.is_active ? false : true} })
        options.push(...group)
        
    });

    return (
        <>
           <div>
                    <Header />
                    <main className="container" >
                        <div className="display-queue-logo" style={{padding:0}}>
                            {storeInfo?.store_logo &&  <img src={storeInfo?.store_logo} alt={storeInfo?.store_name} style={{marginRight: "0.5rem",height:"40px",objectFit: "cover" }}/>}
                            
                            <Location name={storeInfo?.store_name} />
                        </div>
                        {
                            isQueueAvailable ? (
                                <>
                                    <Welcome welcomeTxt={storeInfo?.store_sub_heading}/>
                                    <HorizontalLine />


                    <Carousel 
                        arrows={false}
                        className={`tick-card-carousel ${queueOptions?.length === 3 ? "card-carousel-center" : "card-carousel-left"}`}
                        draggable={true}
                        infinite={false}
                        keyBoardControl
                        responsive={carouselCss}
                        swipeable={true}
                        // partialVisbile={queueOptions?.length > 2 ? true : false}
                        >
                        {queueOptions?.map((queue,i) => <QueueCard store_meta={storeInfo} key={i} queObj={queue} tickets={groupedTickets[queue.prefix]} />)}
                    </Carousel>

                                    <HorizontalLine />
                                    
                                    <form onSubmit={handleNewTicket}>
                                        <div className="input-boxes">
                                        <div style={{ marginBottom: "1.6rem" }}>
                                                <div className="input-box" style={{ padding: "3px 10px 1px 10px", margin: 0 }}>
                                                    <label>
                                                        No. of Guests
                                                        <span>*</span>
                                                    </label>
                                                    <Select
                                                        menuColor='red'
                                                        styles={styleSelect}
                                                        options={options}
                                                        onChange={(value) => setFormData({ ...formData, queue_id: +value.value })} />

                                                </div>
                                                {checkErr && !formData.queue_id && <p className="error-message"><small>Please Select No. of Guest</small></p>}
                                                            </div>
                                            <InputBox labelName="Name" placeholder="Enter your name"
                                                err={checkErr && !formData.customer_name ? true : false}
                                                value={formData.customer_name}
                                                setValue={(customer_name) => setFormData({ ...formData, customer_name })} />

                                            <InputBox labelName="Your Mobile" placeholder="Mobile number"
                                                err={checkErr && !formData.customer_phone ? true : false}    >
                                                <CountryDropdown countryCode={storeInfo?.store_default_country_code} value={formData.customer_phone} setValue={(value) => setFormData({ ...formData, customer_phone: value })} />
                                            </InputBox>
                                        </div>

                                        {sms_alerts && 
                                            <div className="message-checkbox" style={{marginLeft: "0.3rem"}}>
                                            <CheckBox name='Send me a SMS when the table is ready'
                                                value={formData?.send_sms}
                                                setValue={(value) => setFormData({ ...formData, send_sms: value })}
                                            />
                                            </div>
                                        
                                        }
                                        
                
                                        <HorizontalLine />
                                        <div className="safety-message">
                                            {storeInfo?.store_footer_text || "Please wear Masks and maintain safe social distancing limits during your visit."}
                                        </div>
                                        <div className="queue-button">
                                            <button type="submit" className="button">
                                            {loading ? 
                                                <Loader  
                                                    type="ThreeDots"
                                                    color="white"
                                                    height={"12px"}
                                                    width={"45px"}
                                                />  :
                                                "GET QUEUE NUMBER"}
                                                
                                            </button>
                                        </div>
                                    </form>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={true}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                    {duplicateTicket && 
                                       <DuplicatePopup loading={loading} ticket={duplicateTicket} handleExitQ={handleExitQ} handleContinueQ={handleContinueQ}/>
                                  }
                                </>
                            ) : (
                                <QueueAvailable />
                            )
                        }
                    </main>
                    <Footer />    
            </div>

        </>
    );
}

const styleSelect = {
    control: base => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        '&:hover': {
            backgroundColor: "var(--color_primary)",
            color: "whitesmoke"
        },
        backgroundColor: isSelected ? "var(--color_primary)" : "whitesmoke",
        color: isSelected ? "whitesmoke" : "black"
    })
};

const  carouselCss ={
    desktop: {
        breakpoint: {
        max: 3000,
        min: 1024
        },
        items: 3,
        partialVisibilityGutter: 20
        },
        // iphone: {
        //     breakpoint: {
        //     max: 667,
        //     min: 375
        //     },
        //     items: 2,
        //     partialVisibilityGutter: 40
        // },
        // iphoneNormal: {
        //     breakpoint: {
        //     max: 736,
        //     min: 414
        //     },
        //     items: 3,
        //     partialVisibilityGutter: 5
        // },
        // iphonex: {
        //     breakpoint: {
        //     max: 375,
        //     min: 0
        //     },
        //     items: 2,
        //     // partialVisibilityGutter: 30
        // },
        mobile: {
            breakpoint: {
            max: 464,
            min: 0
            },
            items: 3,
            partialVisibilityGutter: 30
        },
 
        // tablet: {
        //     breakpoint: {
        //     max: 1024,
        //     min: 464
        //     },
        //     items: 3,
        //     partialVisibilityGutter: 30
        // }
    }
export default withRouter (QueueHome);
