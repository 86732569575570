import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import "./index.css";

const CountryDropdown = ({value, setValue, countryCode}) => {
    return (
        <>
            <PhoneInput
            inputClass="phone-form"
            country={countryCode?.toLowerCase() || 'sg'}
            value={value}
            onChange={phone => setValue(phone)}
            inputStyle={{
                border: "none"
            }}
            />
        </>
    )
}

export default CountryDropdown;
