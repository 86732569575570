import React from 'react'
import Loader from "react-loader-spinner";

const Spinner = () => {
    return (
        <div className="loader-wrapper">
            <Loader  
                type="ThreeDots"
                color="#532D6D"
                height={75}
                width={75}
                timeout={6000000}
            />
        </div>
    )
}

export default Spinner
