import React, {useState, useEffect} from 'react'
import Loader from "react-loader-spinner";
import { withRouter } from 'react-router'
import Footer from '../../../components/Footer';
import ManagerHeader from '../../../components/ManageHeaders'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { addDays, startOfMonth,endOfMonth, startOfWeek, endOfWeek, addMonths} from 'date-fns'
import Table from './components/Table';
import axios from 'axios';
import moment from 'moment';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../firebase';




const Report = ({merchantKey}) => {

  const [date, setDate] = useState([startOfMonth(new Date()) , new Date()]);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [displayMessage, setDisplayMessage] = useState("")
  const [messageSnap] = useCollectionDataOnce(firestore.collection(`${process.env.REACT_APP_FIRESTORE_META_PATH}/note_messages`).where('all_stores', '==', true).where('is_active', '==', true).where('path', '==', '/dashboard/report'));   
    
  const brandMessages = messageSnap && messageSnap[0]

  useEffect(() => {
    if(date && date.length > 0) {
      setTimeout(() => {
        setLoading(true)
        const url = `${process.env.REACT_APP_SMARTQ_API_BASE_URL}/store/${merchantKey}/analytics?start_date=${moment(date[0]).format('YYYY-MM-DD')}&end_date=${moment(date[1]).format('YYYY-MM-DD')}`
        axios.post(url).then((res) => {setData(res.data.data); setLoading(false)}).catch(e => setLoading(false))

        if(brandMessages && date[0] && date[1] && brandMessages?.condition?.date) {
          if(moment(brandMessages?.condition.date, 'DD-MM-YYYY').isBetween(date[0], date[1]) || moment(moment(date[1])).isBefore(moment(brandMessages?.condition.date, 'DD-MM-YYYY'))) {
            setDisplayMessage(brandMessages.message)
          }else {
            setDisplayMessage("")
          }
        }else{
          setDisplayMessage("")
        }

      }, 1000);
    }else {
      setData([])
      setLoading(false)
    }

    
    


  }, [date, merchantKey, brandMessages])


  const columns = React.useMemo(() => table_column,[])



    return (
       <>
         <ManagerHeader navActive={true}/>
          <div className="dash-container dash-container-right mt-5">
            

            <div className="report-container">
              <div className="top-header">
                <span style={{fontSize: "1.1rem", marginRight: "0.5rem", fontWeight:"500"}}>Select Date Range :</span> 
                <DateRangePicker
                  format="dd/MM/y"
                  onChange={setDate}
                  value={date}
                />
                <span className="badge"
                  onClick={() => setDate([startOfWeek(addDays(new Date(), -7)), endOfWeek(addDays(new Date(), -7))])}
                
                ><small>Last Week</small></span>
                <span className="badge" 
                onClick={() => setDate([startOfMonth(new Date()), endOfMonth(new Date()) ])}
                ><small>This Month</small></span>
                <span className="badge" 
                onClick={() => setDate([startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1)) ])}
                ><small>Last Month</small></span>
              </div>
              {displayMessage && 
              
              <div className="warning-note"><b>Note: </b> <br /> <>&nbsp;</> <>&nbsp;</> {displayMessage}</div> 
              } 
              {loading ? 
                <div className="table-loading">
                  <Loader 
                    type="ThreeDots"
                    color="#532d6d"
                    height={"20px"}
                    width={"65px"} />
                </div>
                :
                <div style={{marginTop: "1rem"}}>
                  <Table  columns={columns} data={data}/>
                </div>
              }
            </div>
             
          </div>
         <Footer float={true}/>
       </>
    )
}

export default withRouter (Report)


const table_column = [
  {
    Header: 'Date',
    accessor: 'date',
    width: 250,
    Footer: 'Summary',
    
  },
  {
    Header: 'Queue no. Generated',
    accessor: 'total_queues',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.total_queues + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
  },
  {
    Header: 'Queue tickets - Seated',
    accessor: 'total_queue_seated',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.total_queue_seated + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
  },
  {
    Header: 'Queue tickets - No Show',
    accessor: 'total_queue_no_show',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.total_queue_no_show + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
  },
  {
    Header: 'Queue tickets - Exit',
    accessor: 'total_queue_existed',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.total_queue_existed + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
  },
  {
    Header: 'Avg. PAX per queue',
    accessor: 'avg_pax_per_queue',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => +(row.values.avg_pax_per_queue / info.rows.length) + sum, 0),
        [info.rows]
      )

      return <>{Math.round(total)}</>
    },
  },

  {
    Header: 'Avg. Queue to Call time <br /> <span class="th-header-small"><small>(mins)</small></span>',
    accessor: 'avg_queue_call_time',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => +(row.values.avg_queue_call_time / info.rows.length) + sum, 0),
        [info.rows]
      )

      return <>{total.toFixed(1)}</>
    },
  },
  {
    Header: 'Avg. Call to <br /> Seat time <br /> <span class="th-header-small"><small>(mins)</small></span>',
    accessor: 'avg_queue_seat_time',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) =>  +(row.values.avg_queue_no_show_time === "-" ? 0 :  row.values.avg_queue_seat_time / info.rows.filter(row => row.values.avg_queue_seat_time).length )  + sum, 0),
        [info.rows]
      )

      return <>{isNaN(total) ? "0.0" : total.toFixed(1)}</>
    },
    Cell: ({ row: { original } }) => {
      return <>{original.avg_queue_seat_time <= 0 ? "-" :  original.avg_queue_seat_time}</>
    },
  },
  {
    Header: 'Avg. Call to <br /> No Show time <br /> <span class="th-header-small"><small>(mins)</small></span>',
    accessor: 'avg_queue_no_show_time',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) =>  +(row.values.avg_queue_no_show_time === "-" ? 0 : row.values.avg_queue_no_show_time / info.rows.filter(row => row.values.avg_queue_no_show_time).length)  + sum, 0),
        [info.rows]
      )

      return <>{isNaN(total) ? "0.0" : total.toFixed(1)}</>
    },
    Cell: ({ row: { original } }) => {
      return <>{original.avg_queue_no_show_time <= 0 ? "-" :  original.avg_queue_no_show_time}</>
    },
  },
  {
    Header: 'No. of SMS sent',
    accessor: 'total_sms_sent',
    Footer: info => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.total_sms_sent + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
  },
]