import React from 'react'

const notFound = () => {
    return (
       <div className="not-found-container" >
           <h1 style={style.h1Style}>404</h1>
           <h2 style={style.h2Style}>Page not found</h2>
           <div style={style.footerStyle}>
                <p className="center-text" style={style.footerStyleP}><b>Tabsquare SmartQ </b>
                </p>
           </div>
       </div>
    )
}

export default notFound;

const style = {
    footerStyle : {
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
        padding: "12px 0",
        color: "white",
        fontWeight: "600",
        backgroundColor: "#532D6D"
    },
    footerStyleP: {
        fontWeight: "300",
        fontSize: "20px",
        textAlign: "center",
    },
    h1Style: {
        paddingTop: "100px", 
        fontSize: "80px",
    },
    h2Style: {
        marginTop: "20px", 
        fontSize: "35px",
    },
    logo: {
        display: "inline-block",
        width: "100px",
    }
}