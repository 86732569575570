import React from 'react'
import { useTable, useSortBy } from 'react-table'
import ReactHtmlParser from 'html-react-parser'

const Table = ({ columns, data }) => {
     // Use the state and functions returned from useTable to build your UI
     const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
      } =  useTable(
        {
          columns,
          data,
        },
        useSortBy
      )
    
    return (
        <table {...getTableProps()} style={{marginBottom: "2rem", width:"95%", marginTop:"2rem"}}>
        <thead >
          {headerGroups.map(group => (
            <tr {...group.getHeaderGroupProps()}>
              {group.headers.map(column => (
                <th {...column.getHeaderProps(
                    {
                        ...column.getSortByToggleProps(),     
                        style: { ...column, cursor: 'pointer', fontWeight:"500", fontSize:".9rem" , padding: "0.7rem" }
                        
                    })} 
                  >
                    {ReactHtmlParser(column.render('Header'))}
                    <span>
                        {column.isSorted
                        ? column.isSortedDesc
                            ? ' 🞃 '
                            : ' 🞁 '
                        : ''}
                    </span>
                    
                  </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          
          {rows?.length <= 0 ?
            <tr>
             <td colSpan="10" style={{textAlign:"center"}}>No Data Found, Try Selecting a Different Date Range.</td>
            </tr>
            :
            rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
              
            })
          }
            
          
          
        </tbody>
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <th {...column.getFooterProps({style: {fontWeight:"500"}})}>{column.render('Footer')}</th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    )
}

export default Table
