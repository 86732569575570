import React from 'react';
import "./index.css"

const QueueCard = ({expiredTicket, tick_no,ticket_type, color_code}) => {
    return (
        <div className="queue-card-rectangle" style={{marginTop:"15px"}}>
            <div class="queue-card-semicircle"></div>
            <div className="queue-card-dotted-line"></div>
            <div className="queue-card-content">
                <p style={expiredTicket && {color: color_code ? color_code : "#ED2E7E"}}>{tick_no}</p>
                <p style={expiredTicket && {color:  color_code ? color_code : "#ED2E7E"}}> <small>{ticket_type}</small> </p>
            </div>
        </div>
    )
}

export default QueueCard;
