import React, { useEffect, useState } from "react";
import { useIsOnline } from 'react-use-is-online';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { DisplayQueue, Login, ManageHome, QueueSettings, Report } from "./pages/ManageQueue";
import { QueueHome, TicketInfo } from "./pages/Queue";
import { history } from "./utils/history";
import ProtectedRoutes from "./protectRoutes"
import notFound from "./components/404";
import UserRoutes from "./userRoutes";
import unAuthorized from "./components/401";
import {  ToastContainer } from "react-toastify";

function App() {
  const { isOffline, } = useIsOnline();
  const ticketInfo = JSON.parse(localStorage.getItem("ticket_info"))
  const [offlineMode, setOfflineMode] = useState(false)
  const hostname = window.location.hostname;

  let isManage = false;

  if (hostname === process.env.REACT_APP_MANAGE_HOST_NAME) {
    isManage = true
  }

  useEffect(() => {

    setOfflineMode(isOffline)

    if(isOffline){
      
      setTimeout(() => {
        if(isOffline){
          window.location.reload()
        }
      }, 10000);

    }

  }, [isOffline])



  useEffect(() => {
    if (isManage) {
      const timeoutID = window.setTimeout(() => {
        window.location.reload()
      }, 1000 * 60 * 60 * 2); // Time set for 2 hours
      return () => window.clearTimeout(timeoutID);
    }
  }, [isManage]);



  return (
    <Router history={history}>
      {offlineMode && <div className="network-status">
        Lost Connection! Check your network status.
      </div>
      }


      {isManage ?
        <>
          <ToastContainer
            closeButton={false}
            position="bottom-right"
            hideProgressBar={true}
            rtl={false}
            autoClose={false}
            draggable

          />
          <Switch>
            <Route path='/401' component={unAuthorized} />
            <Route path='/dashboard/login' exact component={Login} />
            <ProtectedRoutes path="/dashboard/manage-queue" exact component={ManageHome} />
            <ProtectedRoutes path='/dashboard/queue-settings' exact component={QueueSettings} />
            <ProtectedRoutes path='/dashboard/queue-list' exact component={DisplayQueue} />
            <ProtectedRoutes path='/dashboard/report' exact component={Report} />
            <Redirect path='/' exact to="/dashboard/manage-queue" />
            <Redirect from='*' to='/401' />
          </Switch>
        </>
        :
        <Switch>
          <Route path='/404' component={notFound} />
          <UserRoutes path='/:slug' exact component={QueueHome} />
          <Route path='/:slug/view-ticket' exact component={TicketInfo} ticketInfo={ticketInfo} />
          <Redirect from='*' to='/404' />
        </Switch>

      }

    </Router>
  );
}

export default App;
