import React from 'react'
import tabFooterLogo from "../../assets/images/Powered-by-tabsquare.png"

const Footer = ({float, imgWidth}) => {
    return (
        <footer className={`${float ? 'footer-float': 'footer-fixed'}`} >
            <img src={tabFooterLogo} alt="Powered by Tabsquare" style={{ width: imgWidth }} />
        </footer>
    )
}

export default Footer