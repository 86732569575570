import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
const CancelPopup = ({handleAction,closeModal}) => {
    return (
        <Popup open={true} modal className="share-pop" closeOnDocumentClick onClose={closeModal} >
        <div className="modal">
            {/* <button className="close" onClick={() => closeModal(false)}> &times; </button> */}
            {/* <div style={{padding: "15px",backgroundColor:"#FFCC00",color:"#color-active", borderRadius:"10px" }} >
                <h2 style={{textAlign:"center"}}>Exit Queue ?</h2>
                </div> */}
            <div className="modal-container" style={{padding: "20px", display:"flex", flexFlow:"column", alignItems:"center"}}>
                <p style={{fontSize: "1rem"}}>Are you sure you would like to exit the queue?</p>

                <div style={{width:"100%", marginTop:"1rem", display: "flex", justifyContent:"end"}}>
                    <button style={{marginLeft:".5rem", backgroundColor: "transparent"}} className="btn btn-sm " onClick={() => closeModal(false)} >Go Back</button>
                    <button style={{marginLeft:".5rem"}} className="btn  btn-sm" onClick={handleAction}>Exit Queue</button>
                </div>

            </div>
        </div>
      </Popup>
    )
}

export default CancelPopup
