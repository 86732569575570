import React,{useState,useRef}from 'react'
import AutoPlayMethods from "./carousel";
import TicketPortrait from './TicketPortrait';
const TicketColumnsNewPortrait = ({StoreDetails, StoreInfo, merchantKey }) => {

  // const data = [
  //   {type: "video", src: "https://storage.googleapis.com/loyalty_app_images/smartq/kfc-promo.mp4"},
  //   {type: "image", src: "https://via.placeholder.com/768x500"},
  //   {type: "video", src: "https://storage.googleapis.com/loyalty_app_images/smartq/promo3.mp4"},
  //   {type: "image", src: "https://via.placeholder.com/768x500"},
  // ]

  const ref = useRef(null)
  const [isShown, setIsShown] = useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column-reverse", height:"100%", backgroundColor:"black" }}>

      <div ref={ref}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)} style={{ height: "80vh", backgroundColor: "black" }}  >
      {isShown && <p className="resolution-info">{ref.current.clientWidth}x{ref.current.clientHeight}</p>}
        <AutoPlayMethods data={StoreDetails?.promotions || []} />
      </div>
      <TicketPortrait StoreDetails={StoreDetails} StoreInfo={StoreInfo} merchantKey={merchantKey}  />

    </div>

  )
}

export default TicketColumnsNewPortrait;
