import React from 'react'
import { firestore } from '../../../../firebase';

const TableView = ({storeInfo, handlePopup, merchantKey}) => {

    const handlePause = async (index) => {
        const docRef = firestore.collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`).doc(merchantKey);

        const queue = storeInfo.queue_options;


        queue[index].is_active = !queue[index].is_active;


        await docRef.set({
            queue_options:queue
            
        }, {merge:true});
    }

    return (
        <div className="table-wrap-2" style={{marginBottom: "4rem"}}>
            <div className="table-header">
                <h2>Queue Options</h2>
                <button className="btn btn-primary btn-normal" onClick={() => handlePopup("ADD")} >Add New</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Prefix</th>
                        <th>Min PAX</th>
                        <th>Max PAX</th>
                        <th width="25%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {storeInfo?.queue_options && storeInfo.queue_options.length >= 0 && (
                        storeInfo.queue_options.map((option,index) => {
                            return (
                                <tr key={index}>
                                    <td>{index +1}</td>
                                    <td>{option.name}</td>
                                    <td>{option.prefix}</td>
                                    <td>{option.min_pax}</td>
                                    <td>{option.max_pax}</td>
                                    <td><button className="btn btn-light" style={{padding:0, color:"#0041ff"}} onClick={() => handlePopup("EDIT", index)}> Edit</button> <button className="btn btn-light" style={{color:option.is_active ? "red" : "green" }}  onClick={() => handlePause(index)}>{option.is_active ? "PAUSE": "CONTINUE" }</button> </td>
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TableView
