import firebase from "firebase/app";
import "firebase/firestore";

const serviceAccount = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE,
    messagingSenderId: process.env.REACT_APP_MESSAGE_ID,
    appId: process.env.REACT_APP_APP_ID
}

firebase.initializeApp(serviceAccount)

firebase.firestore().settings({experimentalForceLongPolling: true})

const firestore = firebase.firestore();


export { firestore, firebase};